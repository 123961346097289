/* eslint-disable react/jsx-props-no-spreading, react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import styles from '../../styles/sections/home.module.scss';

const messages = defineMessages({
    home: {
        id: 'content.home',
        defaultMessage: `
                «&nbsp;Nous croyons que plus un projet
                laisse de place à de l’insubordination,
                donc à la créativité libre, plus il sera
                mémorable et surprenant.&nbsp;»
            `,
    },
});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const HomeSection = ({ className }) => {
    const intl = useIntl();
    return (
        <section className={classNames([styles.container, { [className]: className !== null }])}>
            <h1
                data-text
                className={styles.title}
                dangerouslySetInnerHTML={{ __html: intl.formatMessage(messages.home) }}
            />
        </section>
    );
};

HomeSection.propTypes = propTypes;
HomeSection.defaultProps = defaultProps;

export default HomeSection;
