export default {
    locale: 'fr',
    messages: {
        'meta.title': 'Insubordination',
        'meta.title_meta': '{title} | Insubordination',
        'meta.description':
            'Atelier de création transdisciplinaire qui crée des histoires évolutives multiformes et multiplateformes.',
        'meta.keywords':
            'Atelier de création, création, art, art visuel, design, publicité, identité, fil narratif, journalisme, documentaire, créateurs, artistes, histoires évolutives, multiplateforme, multiforme, Catherine Martellini, Simon Beaudry, numérique, interactif, expérience.',
        'meta.title_401': 'Error 401',
        'meta.title_403': 'Error 403',
        'meta.title_404': 'Error 404',
        'meta.title_500': 'Error 500',

        'route.home': 'nous-croyons',
        'route.insubordinator': 'insubordinateur',
        'route.weAre': 'nous-sommes',
        'route.weCreate': 'nous-creons',
        'route.team': 'equipe',
        'route.projects': 'projets',
        'route.contact': 'contacts',
        'route.phone': 'telephone',
        'route.mail': 'courriel',
        'route.credits': 'inf',

        'page.title.home': 'Nous croyons',
        'page.title.insubordinator': 'Insubordinateur',
        'page.title.weAre': 'Nous sommes',
        'page.title.weCreate': 'Nous créons',
        'page.title.team': 'Équipe',
        'page.title.projects': 'Projets',
        'page.title.contact': 'Contact',
        'page.title.phone': 'Téléphone',
        'page.title.mail': 'Courriel',
        'page.title.credits': 'Inf',

        'errors.title_401': 'Error 401',
        'errors.description_401': 'You are not authorized to access this page.',
        'errors.title_403': 'Error 403',
        'errors.description_403': 'Access to this page is forbidden',
        'errors.title_404': 'Error 404',
        'errors.description_404': 'This page doesn’t exists',
        'errors.title_500': 'Error 500',
        'errors.description_500': 'There was an error',
        'errors.goto_home': 'Go to home',

        'content.translate.disclaimer':
            'Avis de non-responsabilité : La version officielle de ce site est en français. Voyez le résultat dans d’autres langues avec un outil de traduction sans nuances.',

        'content.newsletter_description':
            'Laissez-nous vos coordonnées<br/>pour suivre l’évolution<br/>de notre insubordination.',
        'content.email_label': 'Courriel',
        'content.send_button': 'Envoyer',

        'content.homeLink': 'Nous croyons',
        'content.insubordinatorLink': 'Insubordinateur',
        'content.weAreLink': 'Nous sommes',
        'content.createLink': 'Nous créons',
        'content.teamLink': 'Équipe',
        'content.projectsLink': 'Projets',
        'content.contactLink': 'Coordonnées',
        'content.footerLink': 'Inf',

        'content.tagline': 'Pour celles et ceux qui croient',
        'content.home': `«&nbsp;Nous croyons que plus un projet
        laisse de place à l’insubordination,
        donc à la créativité libre, plus il sera
        mémorable et surprenant.&nbsp;»
        `,
        'content.title': 'Voici l’insubordinateur',
        'content.subtitle': 'Mesurez les effets du taux d’insubordination sur',
        'content.weAre': `Nous sommes un atelier
            de création transdisciplinaire
            qui allie la force d’impact
            de l’art VISUEL et de la publicité avec la rigueur de la
            méthode journalistique.
        `,
        'content.weAreDesc': 'Nous créons des histoires multiformes et multiplateformes.',

        'content.line1': 'Pour celles et ceux qui croient',
        'content.line2': 'Tout est possible',
        'content.line3': 'Fuck the world',
        'content.line4': 'Contenu libre',
        'content.line5': 'Contenu de marque',
        'content.line6': 'All in',
        'content.line7': 'Courage',
        'content.line8': 'Intuition',
        'content.line9': 'Curiosité',

        'content.videoCredits': 'Pour les crédits, c’est par ici.',

        'content.insub.title': 'Voici l’insubordinateur',
        'content.insub.subtitle': 'Découvrez vous-même Les effets du taux d’insubordination sur',
        'content.insub.budget': 'Entrez votre budget',
        'content.insub.budgetDefault': 'Et choisissez votre % d’insubordination',
        'content.insub.budgetEconomy': 'Moins cher de',
        'content.insub.budgetDiff': 'Plus cher de',

        'content.team.intro': `Nous sommes un duo de création aux forces complémentaires en art visuel, publicité, design, rédaction et journalisme.`,
        'content.team.simon': 'Cofondateur, associé, directeur, de création',
        'content.team.catherine': 'Cofondatrice, associée, directrice, des contenus',
        'content.team.desc': `Nous sommes le centre d’un collectif d’insubordonnés qui fluctue selon la nature des mandats et leur ampleur.`,

        'content.projects': 'Projets',

        'content.contact.title': 'Coordonnées',
        'content.contact.description':
            'Nous sommes une entreprise nomade qui partageons notre existence entre hochelaga, trois-pistoles et la lune.',

        'content.credits.title': 'Insubordination a été fondée le 01 Janvier 2020',
        'content.credits.subtitle': 'Ce site a été créé avec l’atelier ami Folklore',
        'content.credits.tagline':
            'Notre entreprise est québecoise mais notre insubordination est transculturelle',
    },
    routes: {
        home: '/',
        insubordinator: '/insubordinateur',
        weAre: '/nous-sommes',
        weCreate: '/nous-creons',
        team: '/equipe',
        projects: '/projets',
        contact: '/contacts',
        phone: '/telephone',
        mail: '/courriel',
        credits: '/inf',
    },
};
