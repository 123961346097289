/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';

import hoche from '../../img/hoche-front.jpg';
import hocheTag from '../../img/hoche.jpg';
import hoche2 from '../../img/hoche-back.jpg';
import bum1 from '../../img/insubordinator/bum1.jpg';
import bum2 from '../../img/insubordinator/bum2.jpg';
import pistole from '../../img/3pistoles.jpg';
import pistole2 from '../../img/3pistoles-back.jpg';
import pistole3 from '../../img/3pistoles-2.jpg';
import Trait from '../icons/Trait';
import Pin from '../icons/Pin';
import Moon from '../icons/Moon';

import styles from '../../styles/sections/contact.module.scss';

const messages = defineMessages({
    contact: {
        id: 'content.contact.title',
        defaultMessage: 'Coordonnées',
    },
    description: {
        id: 'content.contact.description',
        defaultMessage:
            'Nous sommes une entreprisenomade qui partageons notreexistence entre hochelaga,trois-pistoles et la lune.',
    },
});

const propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ContactSection = ({ className, value }) => {
    const intl = useIntl();
    const params = useParams();
    const intervalRef = useRef({});
    const [imageIndex, setImageIndex] = useState(0);
    const imagesHoche = useMemo(() => {
        if (value >= 80 && value <= 100) {
            return [bum2, bum1, hocheTag];
        }
        return [hoche, hoche2];
    }, [value]);

    const imagesPistole = useMemo(() => {
        if (value >= 80 && value <= 100) {
            return [pistole2, pistole3];
        }
        return [pistole, pistole2];
    }, [value]);

    const switchImage = useCallback(() => {
        if (imageIndex === imagesHoche.length - 1) {
            return setImageIndex(0);
        }
        return setImageIndex(idx => idx + 1);
    }, [imageIndex, setImageIndex]);

    useEffect(() => {
        if (params.slug === 'contact' || params.slug === 'contacts') {
            intervalRef.current = setInterval(switchImage, 3000);
        }
        return () => clearInterval(intervalRef.current);
    }, [switchImage, params]);

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <section className={styles.adresses}>
                <div className={styles.top}>
                    <h1 data-title className={styles.title}>
                        {intl.formatMessage(messages.contact)}
                    </h1>
                    <h2 data-text className={styles.description}>
                        {intl.formatMessage(messages.description)}
                    </h2>
                </div>
                <div className={styles.bottom}>
                    <div className={classNames([styles.image, styles.imageHoche])}>
                        <a
                            className={styles.anchorOnImage}
                            href="https://www.google.com/maps/place/1468+Rue+Saint-Germain,+Montr%C3%A9al,+QC+H1W+2T1/@45.5401248,-73.5436681,17.32z/data=!4m5!3m4!1s0x4cc91b9ab9d22b13:0xf8d8524feba8319c!8m2!3d45.5400989!4d-73.5420557"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {imagesHoche.map((img, idx) => (
                                <img
                                    key={img}
                                    src={img}
                                    alt="Hochelaga"
                                    style={{ opacity: `${imageIndex === idx ? '1' : '0'}` }}
                                />
                            ))}
                        </a>
                        <a
                            href="https://www.google.com/maps/place/1468+Rue+Saint-Germain,+Montr%C3%A9al,+QC+H1W+2T1/@45.5401248,-73.5436681,17.32z/data=!4m5!3m4!1s0x4cc91b9ab9d22b13:0xf8d8524feba8319c!8m2!3d45.5400989!4d-73.5420557"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div
                                data-text
                                className={classNames([styles.adress, styles.adressHoche])}
                            >
                                <p className={styles.adressTitle}>Hochelaga</p>
                                <p className={styles.adressDetails}>
                                    <span>1468, Saint-Germain</span>
                                    <span>Montréal, Québec</span>
                                    <span>H1W 2T1</span>
                                    <span>
                                        <Pin />
                                    </span>
                                </p>
                            </div>
                        </a>
                    </div>

                    <div className={styles.trait}>
                        <Trait />
                    </div>
                    <div className={styles.image}>
                        <a
                            className={styles.anchorOnImage}
                            href="https://www.google.com/maps/place/159+Rue+de+la+Gr%C3%A8ve,+Rivi%C3%A8re-Trois-Pistoles,+QC+G0L+2E0/@48.1200566,-69.2218142,12.96z/data=!4m5!3m4!1s0x4cbe20088fc43413:0x547142c79d50bff4!8m2!3d48.1078067!4d-69.2162187"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {imagesPistole.map((img, idx) => (
                                <img
                                    key={img}
                                    src={img}
                                    alt="Hochelaga"
                                    style={{ opacity: `${imageIndex === idx ? '1' : '0'}` }}
                                />
                            ))}
                        </a>

                        <a
                            href="https://www.google.com/maps/place/159+Rue+de+la+Gr%C3%A8ve,+Rivi%C3%A8re-Trois-Pistoles,+QC+G0L+2E0/@48.1200566,-69.2218142,12.96z/data=!4m5!3m4!1s0x4cbe20088fc43413:0x547142c79d50bff4!8m2!3d48.1078067!4d-69.2162187"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <div
                                data-text
                                className={classNames([styles.adress, styles.adressPistoles])}
                            >
                                <p className={styles.adressTitle}>Trois-Pistoles</p>
                                <p className={styles.adressDetails}>
                                    <span>La maison bleue</span>
                                    <span>À la grève à tobin</span>
                                    <span>(Prononcez tobune)</span>
                                    <span>
                                        <Pin />
                                    </span>
                                </p>
                            </div>
                        </a>
                    </div>
                    <a
                        href="https://www.maxrichter-sleep.com/it"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div data-text className={classNames([styles.adress, styles.adressMoon])}>
                            <p className={styles.adressTitle}>Lune</p>
                            <p className={styles.adressDetails}>
                                <Moon className={styles.moon} />
                                <span>https://www.maxrichter-sleep.com/it</span>
                            </p>
                        </div>
                    </a>
                </div>
            </section>
        </div>
    );
};

ContactSection.propTypes = propTypes;
ContactSection.defaultProps = defaultProps;

export default ContactSection;
