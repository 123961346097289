import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    traitColor: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    traitColor: '#B19554',
    color: '#B19554',
};

const LogoLong = ({ className, traitColor, color }) => (
    <svg
        width="50"
        height="46"
        viewBox="0 0 50 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M9.9 0H0V45.8H9.9V0Z" fill={color} />
        <path d="M29.6998 0H19.7998V45.8H29.6998V0Z" fill={color} />
        <path d="M49.4 0H39.5V45.8H49.4V0Z" fill={color} />
        <path d="M29.6998 0H19.7998L39.4998 45.8H49.3998L29.6998 0Z" fill={traitColor} />
    </svg>
);

LogoLong.propTypes = propTypes;
LogoLong.defaultProps = defaultProps;

export default LogoLong;
