import isObject from 'lodash/isObject';

export const pascalCase = str =>
    str
        .replace(/[^a-z0-9]+/gi, ' ')
        .replace(/(\w)(\w*)/g, (g0, g1, g2) => `${g1.toUpperCase()}${g2.toLowerCase()}`)
        .replace(/\s+/gi, '');

export const isMessage = message => isObject(message) && typeof message.id !== 'undefined';

export const getComponentFromName = (components, name, defaultComponent = null) => {
    const componentName = pascalCase(name);
    return components[componentName] || defaultComponent;
};

// export const getRelativeValue = (value, coef = null) =>
//     coef !== null
//         ? (value / coef)
//               .toPrecision()
//               .toString()
//               .slice(-1) / 10
//         : value.toString().slice(-1) / 10;

export const getRelativeValue = (value, coef = 10) => {
    const restant = value % coef;
    const oldMin = value - restant;
    const oldMax = oldMin + coef;
    const relativeTemp = (value - oldMin) / (oldMax - oldMin);
    return Math.floor(relativeTemp * 10) / 10;
};

export const loadScript = (url) =>
    new Promise((resolve) => {
        const script = document.createElement('script');
        script.src = url;
        script.onload = () => resolve;
        document.getElementsByTagName('head')[0].appendChild(script);
    });
