/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getRelativeValue } from '../../lib/utils';

const propTypes = {
    value: PropTypes.number.isRequired,
};

const defaultProps = {};

const Animations = ({ value }) => {
    const colors = ['#FFFF00', '#FF00FF', '#00FF00', '#FF3333'];

    const relativeValue = useMemo(() => getRelativeValue(value), [value]);

    const transformStart = useMemo(() => {
        return '0px';
    }, [value]);

    const transformEnd = useMemo(() => {
        return 800;
    }, [value]);

    const {
        duration,
        animation,
        animationText,
        animationInsubTitle,
        iterationCount,
        direction,
        transformation,
        transformationText,
        animationVideo,
    } = useMemo(() => {
        let durationFinal = '2s';
        let nameFinal = '';
        let textNameFinal = '';
        let insubTitleNameFinal = '';
        let count = 1;
        let dir = 'normal';
        let transform;
        let transformText;
        let animationVideoName;

        if (value < 10) {
            durationFinal = ``;
        }
        if (value >= 10 && value < 20) {
            durationFinal = '';
        }
        if (value >= 20 && value < 30) {
            durationFinal = '';
        }
        if (value >= 30 && value < 40) {
            durationFinal = '';
        }
        if (value >= 40 && value < 50) {
            //
        }
        if (value >= 50 && value < 60) {
            transform = `transform: rotateY(200deg)`;
            nameFinal = 'rumble';
            durationFinal = `${2 - relativeValue * 2}s`;
            count = 'infinite';
            dir = 'alternate';
            animationVideoName = 'colorize';
        }
        if (value >= 60 && value < 70) {
            transform = `transform: rotateY(${relativeValue * 200}deg)`;
            transformText = `transform: rotateX(${relativeValue * 200}deg)`;
            animationVideoName = 'colorize';
        }
        if (value >= 70 && value < 80) {
            nameFinal = 'rotate';
            textNameFinal = 'rotateX';
            count = 'infinite';
            durationFinal = `${3 - relativeValue * 3}s`;
            animationVideoName = 'invert';
        }
        if (value >= 80 && value < 90) {
            insubTitleNameFinal = 'fall';
            transform = `transform: rotateY(200deg)`;
            transformText = `transform: rotateX(200deg)`;
            durationFinal = '2s';
            animationVideoName = 'invert';
        }
        if (value >= 90 && value < 93) {
            nameFinal = 'colorChange';
            textNameFinal = 'colorChange';
            durationFinal = `${2 - relativeValue * 2}s`;
            count = 'infinite';
            dir = 'alternate';
            animationVideoName = 'colorize';
        }
        if (value >= 93 && value < 97) {
            nameFinal = 'colorChangeTwo';
            textNameFinal = 'colorChangeTwo';
            durationFinal = `${2 - relativeValue * 2}s`;
            count = 'infinite';
            animationVideoName = 'colorize';
        }
        if (value > 97) {
            textNameFinal = 'colorChangeThree';
            nameFinal = 'colorChangeThree';
            durationFinal = `0.5s`;
            count = 'infinite';
            animationVideoName = 'colorize';
        }
        return {
            duration: durationFinal,
            animation: nameFinal,
            animationText: textNameFinal,
            animationInsubTitle: insubTitleNameFinal,
            iterationCount: count,
            direction: dir,
            transformation: transform,
            transformationText: transformText,
            animationVideo: animationVideoName,
        };
    }, [value]);

    return (
        <style type="text/css">
            {`
                @keyframes fall {
                    0% {
                        transform: translateY(${transformStart}) rotate(0);
                    }
                    50% {
                        transform: translateY(${transformEnd / 2}px) rotate(-23deg);
                    }
                    90% {
                        transform: translateY(${transformEnd}px) rotate(13deg);
                    }
                    100% {
                        transform: translateY(${transformEnd}px) rotate(0);
                    }
                }

                @keyframes colorChange {
                    0% {
                        color: ${colors[0]};
                    }
                    25% {
                        color: ${colors[1]};
                    }
                    50% {
                        color: ${colors[2]};
                    }
                    75% {
                        color: ${colors[3]};
                    }
                }

                @keyframes colorChangeTwo {
                    0% {
                        color: #fff;
                    }
                    25% {
                        color: #fff494;
                    }
                    50% {
                        color: #fff;
                    }
                    75% {
                        color: #fff494;
                    }
                }

                @keyframes colorChangeThree {
                    0% {
                        color: #012f8f;
                    }
                    25% {
                        color: #032600;
                    }
                    50% {
                        color: #000;
                    }
                    75% {
                        color: #300707;
                    }
                }

                @keyframes rumble {
                    0% {
                        transform: rotate(0deg);
                    }
                    25% {
                        transform: translate(2px, 0px) rotate(-3deg);
                    }
                    50% {
                        transform: translate(0px, -2px) rotate(0deg);
                    }
                    75% {
                        transform: translate(-2px, 0px) rotate(3deg);
                    }
                    100% {
                        transform: translate(0px, 2px) rotate(0deg);
                    }
                }

                @keyframes rotate {
                    0% {
                        transform: rotateY(0deg);
                    }
                    100% {
                        transform: rotateY(360deg);
                    }
                }

                @keyframes rotateX {
                    0% {
                        transform: rotateX(0deg);
                    }
                    100% {
                        transform: rotateX(360deg);
                    }
                }

                [data-title] {
                    animation-name: ${animation} !important;
                    animation-fill-mode: forwards !important;
                    animation-timing-function: ease-in !important;
                    animation-duration: ${duration} !important;
                    animation-iteration-count: ${iterationCount} !important;
                    animation-direction: ${direction};

                    transition: transform 0.3s !important;
                    ${transformation}
                }

                [data-text] {
                    animation-name: ${animationText} !important;
                    animation-fill-mode: forwards !important;
                    animation-timing-function: ease-in !important;
                    animation-duration: ${duration} !important;
                    animation-iteration-count: ${iterationCount} !important;
                    animation-direction: ${direction};

                    transition: transform 0.3s !important;
                    ${transformationText}
                }

                [data-insub-title] {
                    animation-name: ${animationInsubTitle} !important;
                    animation-fill-mode: forwards !important;
                    animation-timing-function: ease-in !important;
                    animation-duration: ${duration} !important;
                    animation-iteration-count: ${iterationCount} !important;
                    animation-direction: ${direction};
                }

                [data-insub-desc] {
                    ${value >= 80 && value < 90 ? 'transform: rotateY(0deg)' : ''}
                }

                [data-poem] {
                    ${value >= 60 && value < 70 ? 'transform: rotateY(0deg)' : ''}
                }

                video {
                    animation-name: ${animationVideo} !important;
                    animation-fill-mode: forwards !important;
                    animation-timing-function: ease-in !important;
                    animation-duration: ${duration || '1s'} !important;
                    animation-iteration-count: ${iterationCount} !important;
                    animation-direction: alternate;
                }

                @keyframes colorize {
                    0% {
                        filter: hue-rotate(25deg);
                    }
                    25% {
                        filter: hue-rotate(75deg);
                    }
                    50% {
                        filter: hue-rotate(140deg);
                    }
                    75% {
                        filter: hue-rotate(250deg);
                    }
                }

                @keyframes invert {
                    0% {
                        filter: invert(0.2);
                    }
                    25% {
                        filter: invert(0.4);
                    }
                    50% {
                        filter: invert(0.6);
                    }
                    75% {
                        filter: invert(0.8);
                    }
                    100% {
                        filter: invert(1);
                    }
                }

                @keyframes invertFast {
                    0% {
                        filter: invert(0);
                    }
                    100% {
                        filter: invert(1);
                    }
                }
            `}
        </style>
    );
};

Animations.propTypes = propTypes;
Animations.defaultProps = defaultProps;

export default Animations;
