/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import gsap from 'gsap';

import { getComponentFromName } from '../../lib/utils';
import useBackgroundInsubordinator from '../../lib/useBgInsubordinator';
import backgrounds from '../../data/backgrounds';

import LogoShort from '../logos/LogoShort';
import TraitBrush from '../icons/TraitBrush';
import Trait from '../icons/Trait';

import styles from '../../styles/partials/background.module.scss';

const propTypes = {
    value: PropTypes.number.isRequired,
    parallaxAmount: PropTypes.number,
    location: PropTypes.string,
    className: PropTypes.string,
};

const defaultProps = {
    parallaxAmount: 80,
    location: 'home',
    className: null,
};

const Background = ({ value, parallaxAmount, location, className }) => {
    const { trait = 'default', backgroundColor = null } = useMemo(
        () => useBackgroundInsubordinator(value),
        [value],
    );

    const Traits = {
        Default: LogoShort,
        Trait,
        Brush: TraitBrush,
    };

    const bgIndexes = Object.keys(backgrounds);
    const backgroundIndex = Math.max(
        0,
        bgIndexes.findIndex((key) => backgrounds[key].name === location),
    );
    const actualBg = backgrounds[bgIndexes[backgroundIndex]];

    const TraitComponent = useMemo(() => {
        let traitComp;
        if (value < 30) {
            traitComp = actualBg.type || 'default';
        } else {
            traitComp = trait;
        }
        return getComponentFromName(Traits, traitComp);
    }, [value]);

    // parallax
    const containerRef = useRef(null);
    const traitRef = useRef(null);
    const containerInfos = useRef(null);
    const windowHeight = useRef(null);

    useEffect(() => {
        const onResize = () => {
            const containerRect = containerRef.current.getBoundingClientRect();
            containerInfos.current = {
                y: containerRect.top + window.scrollY,
                height: containerRect.height,
            };
            windowHeight.current = window.innerHeight;
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        const containerEl = containerRef.current;
        const traitEl = traitRef.current;

        const updateTimelineProgress = () => {
            if (containerInfos === null) {
                return;
            }

            const containerTop = containerInfos.current.y - window.scrollY;
            const containerHeight = containerInfos.current.height;
            const durationDistance = windowHeight.current + containerHeight;
            const progress = 2 * (1 - (windowHeight.current + containerTop) / durationDistance) - 1;

            gsap.set(traitEl, { y: `${progress * parallaxAmount}%` });
        };

        const callback = (entries) => {
            if (entries[0].intersectionRatio > 0) {
                window.addEventListener('scroll', updateTimelineProgress);
            } else {
                window.removeEventListener('scroll', updateTimelineProgress);
            }
        };

        const observer = new IntersectionObserver(callback, {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        });

        if (traitEl !== null) {
            observer.observe(containerEl);
        }

        return () => {
            if (traitEl !== null) {
                observer.unobserve(containerEl);
            }
        };
    }, [actualBg.withTrait, parallaxAmount]);

    return (
        <div
            className={classNames([styles.container, { [className]: className !== null }])}
            ref={containerRef}
        >
            <div
                className={styles.backgroundInner}
                style={{
                    backgroundColor: backgroundColor || actualBg.backgroundColor,
                }}
            />
            {actualBg.withTrait ? (
                <div
                    className={classNames([styles.trait, styles[`trait${backgroundIndex}`]])}
                    ref={traitRef}
                >
                    <TraitComponent traitColor="#fff" color={actualBg.traitColor || '#505050a1'} />
                </div>
            ) : null}
        </div>
    );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
