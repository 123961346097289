/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';

import { useIntersectionObserver } from '../../lib/useObserver';

const propTypes = {
    children: PropTypes.node.isRequired,
    url: PropTypes.string.isRequired,
    // value: PropTypes.number.isRequired,
    className: PropTypes.string,
    onIntersected: PropTypes.func,
};

const defaultProps = {
    className: null,
    onIntersected: null,
};

const Wrapper = ({ children, url, className, onIntersected}) => {
    const params = useParams();
    const {
        ref,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin:'-50%'});

    useEffect(() => {
        if (isIntersecting && params.slug !== url && onIntersected !== null) {
            onIntersected();
        }
    }, [isIntersecting]);

    return (
        <div
            className={className}
            ref={ref}
            style={{
                height: '100%',
            }}
        >
            {children}
        </div>
    );
};

Wrapper.propTypes = propTypes;
Wrapper.defaultProps = defaultProps;

export default Wrapper;
