import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useParams, useHistory } from 'react-router-dom';
import { camelCase } from 'lodash';
import classNames from 'classnames';

import PageMeta from '../partials/PageMeta';
import { getComponentFromName } from '../../lib/utils';
import Navbar from '../partials/Navbar';
import * as Sections from '../sections/sections';
import ComponentWrapper from '../partials/ComponentWrapper';
import Background from '../partials/Background';
import BackgroundWithInsubordination from '../partials/BackgroundWithInsubordination';
// import ParallaxBackgrounds from '../partials/ParallaxBackgrounds';
import useInsubordinator from '../../lib/useInsubordinator';
import Animations from '../insubordinators/Animations';
import Fonts from '../insubordinators/Fonts';
import { routesData as routes, pageTitles as titles } from '../../data/pages';

import styles from '../../styles/pages/home.module.scss';

const propTypes = {
    //
};

const defaultProps = {
    //
};

const HomePage = () => {
    const intl = useIntl();
    const params = useParams();
    const history = useHistory();
    const homeRef = useRef();
    const insubordinatorRef = useRef();
    const weareRef = useRef();
    const createRef = useRef();
    const teamRef = useRef();
    const projectsRef = useRef();
    const contactRef = useRef();
    const footerRef = useRef();

    const [actualPageTitle, setPageTitle] = useState(intl.formatMessage(titles.home));

    const siteSections = useMemo(
        () => ({
            home: { ref: homeRef, slug: 'home', url: intl.formatMessage(routes.home) },
            insubordinator: {
                ref: insubordinatorRef,
                slug: 'insubordinator',
                url: intl.formatMessage(routes.insubordinator),
            },
            weAre: { ref: weareRef, slug: 'we-are', url: intl.formatMessage(routes.weAre) },
            weCreate: {
                ref: createRef,
                slug: 'we-create',
                url: intl.formatMessage(routes.weCreate),
            },
            team: { ref: teamRef, slug: 'team', url: intl.formatMessage(routes.team) },
            projects: {
                ref: projectsRef,
                slug: 'projects',
                url: intl.formatMessage(routes.projects),
            },
            contact: {
                ref: contactRef,
                slug: 'contact',
                url: intl.formatMessage(routes.contact),
            },
            phone: { ref: null, slug: 'phone', url: intl.formatMessage(routes.phone) },
            mail: { ref: null, slug: 'mail', url: intl.formatMessage(routes.mail) },
            credits: { ref: footerRef, slug: 'credits', url: intl.formatMessage(routes.credits) },
        }),
        [],
    );

    const sectionsArray = useMemo(() => Object.values(siteSections), [siteSections]);
    const sectionsInMenu = sectionsArray
        .filter((it) => it.ref !== null)
        .map((it) => {
            return { slug: it.slug, url: it.url };
        });

    // Navigation
    const manualScroll = useRef(false);
    const onSectionChange = useCallback(
        (sectionUrl) => {
            manualScroll.current = true;
            history.push(`/${sectionUrl}`);
        },
        [history],
    );

    useEffect(() => {
        // console.log(params.slug);
        if (params.slug) {
            const isRightRoute = sectionsArray.find((s) => s.url === params.slug);

            if (isRightRoute) {
                const actualParam = camelCase(isRightRoute.slug);
                if (!manualScroll.current && siteSections[actualParam].ref !== null) {
                    siteSections[actualParam].ref.current.scrollIntoView();
                }
                setPageTitle(
                    actualParam !== 'home' ? intl.formatMessage(titles[actualParam]) : null,
                );
            } else {
                history.push('/');
            }
            manualScroll.current = false;
        }
    }, [params]);

    // Insubordinateur
    const [siteValue, setSiteValue] = useState(10);
    const onChangeSiteValue = useCallback(
        (value) => {
            setSiteValue(value);
        },
        [setSiteValue],
    );
    const [resetSite, setResetSite] = useState(false);
    const onResetSite = useCallback(() => {
        setResetSite(true);
        setTimeout(() => setResetSite(false), 15);
    }, [resetSite, setResetSite]);

    const { globalFilter, sectionClassName } = useInsubordinator(siteValue);

    return (
        <div className={styles.container}>
            <Animations value={siteValue} />
            <Fonts value={siteValue} />
            <PageMeta title={actualPageTitle} />
            <div className={styles.inner}>
                <Navbar
                    className={styles.navbar}
                    menu={sectionsInMenu}
                    value={siteValue}
                    resetSite={onResetSite}
                />
                <BackgroundWithInsubordination
                    className={styles.backgroundFixed}
                    value={siteValue}
                />
                { /* <ParallaxBackgrounds
                    className={styles.backgroundParallax}
                    sections={sectionsArray}
                /> */ }
                <div className={styles.sections} style={globalFilter}>
                    {sectionsArray.map((section, sectionI) => {
                        const Section = getComponentFromName(Sections, section.slug);
                        return Section !== null ? (
                            <div
                                key={section.slug}
                                ref={section.ref}
                                className={styles.sectionContainer}
                            >
                                <Background
                                    className={styles.background}
                                    location={section.slug}
                                    value={siteValue}
                                />
                                <ComponentWrapper
                                    className={styles.componentWrapper}
                                    url={section.slug}
                                    firstSection={sectionI === 0}
                                    onIntersected={() => {
                                        onSectionChange(section.url);
                                    }}
                                    value={siteValue}
                                >
                                    <Section
                                        value={siteValue}
                                        onChange={
                                            section.slug === 'insubordinator'
                                                ? onChangeSiteValue
                                                : null
                                        }
                                        resetSite={resetSite}
                                        className={classNames([
                                            styles.section,
                                            styles[section.slug],
                                            styles[sectionClassName],
                                        ])}
                                    />
                                </ComponentWrapper>
                            </div>
                        ) : null;
                    })}
                </div>
            </div>
        </div>
    );
};

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
