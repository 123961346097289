/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import gsap from 'gsap';

import Trait from '../icons/Trait';
import Newsletter from '../forms/SimpleNewsletter';

import styles from '../../styles/sections/projects.module.scss';

const messages = defineMessages({
    projects: {
        id: 'content.projects',
        defaultMessage: 'Projets',
    },
});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ProjectsSection = ({ className }) => {
    const intl = useIntl();
    const [newsletterForm, setNewsletterForm] = useState(false);
    const toggleNewsLetter = useCallback(
        (e) => {
            e.preventDefault();
            setNewsletterForm(!newsletterForm);
        },
        [newsletterForm],
    );

    // parallax

    const links = useRef({
        infolettre: null,
        instagram: null,
        linkedin: null,
        blogue: null,
        facturation: null,
        facebook: null,
        youtube: null,
        contact: null,
    });

    const linksDistances = useMemo(
        () => ({
            infolettre: 300,
            instagram: 225,
            linkedin: 425,
            blogue: 500,
            facturation: 375,
            facebook: 400,
            youtube: 350,
            contact: 450,
        }),
        [],
    );

    const containerRef = useRef(null);

    const containerInfos = useRef(null);
    const windowHeight = useRef(null);

    useEffect(() => {
        const onResize = () => {
            const containerRect = containerRef.current.getBoundingClientRect();
            containerInfos.current = {
                y: containerRect.top + window.scrollY,
                height: containerRect.height,
            };
            windowHeight.current = window.innerHeight;
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);

    useEffect(() => {
        const containerEl = containerRef.current;
        const linksEl = links.current;

        const updateTimelineProgress = () => {
            if (containerInfos === null) {
                return;
            }
            const containerTop = containerInfos.current.y - window.scrollY;
            const containerHeight = containerInfos.current.height;
            const durationDistance = windowHeight.current + containerHeight;
            const progress =
                window.innerWidth < 1400
                    ? 0
                    : 2 * (1 - (windowHeight.current + containerTop) / durationDistance) - 1;

            Object.keys(linksDistances).forEach((elName) => {
                const distance = linksDistances[elName];
                const el = linksEl[elName];
                gsap.set(el, { y: `${progress * distance}%` });
            });
        };

        const callback = (entries) => {
            if (entries[0].intersectionRatio > 0) {
                window.addEventListener('scroll', updateTimelineProgress);
            } else {
                window.removeEventListener('scroll', updateTimelineProgress);
            }
        };
        const observer = new IntersectionObserver(callback, {
            root: null,
            rootMargin: '0px',
            threshold: 0,
        });
        observer.observe(containerEl);

        return () => {
            observer.unobserve(containerEl);
        };
    }, []);

    return (
        <section
            className={classNames([styles.container, { [className]: className !== null }])}
            ref={containerRef}
        >
            <div className={styles.inner}>
                <a
                    data-title
                    className={styles.title}
                    href="https://www.behance.net/Insubordination_IN"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>
                            <Trait color="#000" />
                            {intl.formatMessage(messages.projects)}
                        </div>
                    </div>
                </a>
                <div
                    className={classNames([styles.link, styles.infolettre])}
                    ref={(el) => {
                        links.current.infolettre = el;
                    }}
                >
                    <button
                        type="button"
                        className={styles.letterButton}
                        onClick={toggleNewsLetter}
                    >
                        <div className={styles.projectInner}>
                            <div className={styles.projectInner2}>
                                <em>In</em>folettre
                            </div>
                        </div>
                    </button>
                    <Newsletter
                        className={classNames([styles.form, { [styles.open]: newsletterForm }])}
                    />
                </div>
                <a
                    className={classNames([styles.link, styles.instagram])}
                    href="https://www.instagram.com/insubordination_creation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => {
                        links.current.instagram = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>
                            <em>In</em>stagram
                        </div>
                    </div>
                </a>
                <a
                    className={classNames([styles.link, styles.linkedin])}
                    href="https://ca.linkedin.com/company/insubordination"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => {
                        links.current.linkedin = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>
                            L<em>in</em>ked<em>in</em>
                        </div>
                    </div>
                </a>
                <a
                    className={classNames([styles.link, styles.blogue])}
                    href="https://inblogue.tumblr.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => {
                        links.current.blogue = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>Blogue</div>
                    </div>
                </a>
                <a
                    className={classNames([styles.link, styles.facturation])}
                    href="mailto:facturation@insubordination.co"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => {
                        links.current.facturation = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>Facturation</div>
                    </div>
                </a>
                <a
                    className={classNames([styles.link, styles.facebook])}
                    href="https://www.facebook.com/INsubordinationcreation/"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => {
                        links.current.facebook = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>Facebook</div>
                    </div>
                </a>
                <a
                    className={classNames([styles.link, styles.youtube])}
                    href="https://www.youtube.com/channel/UCCja4NwrgRXWvuKkNSGxSEQ/videos"
                    target="_blank"
                    rel="noopener noreferrer"
                    ref={(el) => {
                        links.current.youtube = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>Youtube</div>
                    </div>
                </a>
                <a
                    className={classNames([styles.link, styles.contact])}
                    href="mailto:info@insubordination.co"
                    ref={(el) => {
                        links.current.contact = el;
                    }}
                >
                    <div className={styles.projectInner}>
                        <div className={styles.projectInner2}>Contact</div>
                    </div>
                </a>
            </div>
        </section>
    );
};

ProjectsSection.propTypes = propTypes;
ProjectsSection.defaultProps = defaultProps;

export default ProjectsSection;
