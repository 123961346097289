/* eslint-disable react/jsx-props-no-spreading, react/no-danger, jsx-a11y/label-has-associated-control, no-unused-vars, no-restricted-globals, eqeqeq */
import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import CircularSlider from '@fseehawer/react-circular-slider';

import { ourSiteData, poetryData as poetry, budgetData } from '../../data/insubordinator';
import generatePoem from '../../lib/usePoetryGenerator';
import CircleButton from '../icons/CircleButton';
import logo from '../../img/IN.png';

import styles from '../../styles/sections/insubordinator.module.scss';

const messages = defineMessages({
    title: {
        id: 'content.insub.title',
        defaultMessage: 'Voici l’insubordinateur',
    },
    subtitle: {
        id: 'content.insub.subtitle',
        defaultMessage: 'Mesurez les effets du taux d’insubordination sur',
    },
    budget: {
        id: 'content.insub.budget',
        defaultMessage: 'Entrez votre budget',
    },
    budgetDefault: {
        id: 'content.insub.budgetDefault',
        defaultMessage: 'Et choisissez votre % d’insubordination',
    },
    budgetEconomy: {
        id: 'content.insub.budgetEconomy',
        defaultMessage: 'Moins cher de',
    },
    budgetDiff: {
        id: 'content.insub.budgetDiff',
        defaultMessage: 'Plus cher de',
    },
});

const propTypes = {
    className: PropTypes.string,
    onChange: PropTypes.func,
    resetSite: PropTypes.bool,
};

const defaultProps = {
    onChange: null,
    resetSite: false,
    className: null,
};

const InsubordinatorSection = ({ className, onChange, resetSite }) => {
    const intl = useIntl();
    const [ourSiteValue, setOurSiteValue] = useState(10);
    const [poetryValue, setPoetryValue] = useState(0);
    const [budgetValue, setBudgetValue] = useState(50);
    const [initialBudget, setInitialBudget] = useState(50000);

    const onChangeInitialBudget = useCallback(
        e => {
            if (e.target.value < 1000000) {
                setInitialBudget(parseInt(e.target.value, 10));
                setBudgetValue(50);
            }
        },
        [initialBudget, setInitialBudget],
    );

    const siteData = useMemo(() => {
        onChange(ourSiteValue);
        const dataIndex =
            ourSiteValue > 97 ? Math.round(ourSiteValue / 10) : Math.floor(ourSiteValue / 10);
        return ourSiteData[dataIndex];
    }, [ourSiteValue]);

    const budgetRatio = useMemo(() => {
        return Math.round(initialBudget * 0.1);
    }, [initialBudget]);

    const budgetDiff = useMemo(() => {
        const percentage = ((-50 + budgetValue) * 2) / 100;
        return Math.round(percentage * budgetRatio);
    }, [budgetValue, initialBudget]);

    const { title, poem, author } = useMemo(() => {
        const indexValue = poetryValue === 100 ? 9 : Math.floor(poetryValue / 10);
        return {
            title: poetry[indexValue].title,
            poem: generatePoem(poetryValue),
            author: poetry[indexValue].author,
        };
    }, [poetryValue]);

    const sizeOfCircleSelector = window.innerWidth > 790 ? 160 : 140;
    const sizeOfFont = window.innerWidth > 790 ? '4rem' : '3rem';

    useEffect(() => {
        if (resetSite === true) {
            setOurSiteValue(10);
        }
    }, [resetSite]);

    return (
        <section className={classNames([styles.container, { [className]: className !== null }])}>
            <div className={styles.inner}>
                <h1 data-title data-insub-title className={styles.title}>
                    {intl.formatMessage(messages.title)}
                </h1>
                <h2 data-text className={styles.subtitle}>
                    {intl.formatMessage(messages.subtitle)}
                </h2>
                <div className={styles.selectorsContainer}>
                    <div className={styles.gridSquare}>
                        <h2 data-text className={styles.selectorTitle}>
                            Notre site
                        </h2>
                        <div className={styles.selector}>
                            <CircularSlider
                                className={styles.selectorImage}
                                label="&nbsp;"
                                min={0}
                                max={100}
                                labelColor="#b19554"
                                valueFontSize={sizeOfFont}
                                progressColorFrom="#000"
                                progressColorTo="#fff"
                                progressSize={26}
                                trackSize={1}
                                trackColor="#b2b2b2"
                                dataIndex={ourSiteValue}
                                onChange={value => setOurSiteValue(value)}
                                knobPosition="bottom"
                                progressLineCap="flat"
                                width={sizeOfCircleSelector}
                            >
                                <CircleButton />
                            </CircularSlider>
                            <div className={styles.logoContainer}>
                                <img src={logo} alt="IN" />%
                            </div>
                        </div>
                        {siteData ? (
                            <h3 data-text data-insub-desc className={styles.resultTitle}>
                                {siteData.title}
                            </h3>
                        ) : null}
                        {siteData ? (
                            <p data-text data-insub-desc className={styles.resultDescription}>
                                {siteData.description}
                            </p>
                        ) : null}
                    </div>
                    <div className={classNames([styles.gridSquare, styles.middleSquare])}>
                        <h2 data-text className={styles.selectorTitle}>
                            De la poésie
                        </h2>
                        <div className={styles.selector}>
                            <CircularSlider
                                label="&nbsp;"
                                min={0}
                                max={100}
                                labelColor="#b19554"
                                valueFontSize={sizeOfFont}
                                valueFont="Arial Black"
                                progressColorFrom="#000"
                                progressColorTo="#fff"
                                progressSize={26}
                                trackSize={1}
                                trackColor="#b2b2b2"
                                dataIndex={poetryValue}
                                onChange={value => setPoetryValue(value)}
                                knobPosition="bottom"
                                progressLineCap="flat"
                                width={sizeOfCircleSelector}
                            >
                                <CircleButton />
                            </CircularSlider>
                            <div className={styles.logoContainer}>
                                <img src={logo} alt="IN" />%
                            </div>
                        </div>
                        {title ? (
                            <h3 data-text className={styles.resultTitle}>
                                {title}
                            </h3>
                        ) : null}
                        {poem ? (
                            <p
                                data-text
                                data-poem
                                className={classNames([
                                    styles.resultDescription,
                                    styles.poetryDesc,
                                ])}
                                dangerouslySetInnerHTML={{ __html: poem }}
                            />
                        ) : null}
                        {author ? (
                            <p data-text className={styles.resultAuthor}>
                                {author}
                            </p>
                        ) : null}
                    </div>
                    <div className={styles.gridSquare}>
                        <h2 data-text className={styles.selectorTitle}>
                            Votre budget
                        </h2>
                        <div className={styles.selector}>
                            <CircularSlider
                                label="&nbsp;"
                                min={0}
                                max={100}
                                labelColor="#b19554"
                                valueFontSize={sizeOfFont}
                                progressColorFrom="#000"
                                progressColorTo="#fff"
                                progressSize={26}
                                trackSize={1}
                                trackColor="#b2b2b2"
                                dataIndex={budgetValue}
                                onChange={value => setBudgetValue(value)}
                                progressLineCap="flat"
                                knobPosition="bottom"
                                width={sizeOfCircleSelector}
                            >
                                <CircleButton />
                            </CircularSlider>
                            <div className={styles.logoContainer}>
                                <img src={logo} alt="IN" />%
                            </div>
                        </div>
                        <h3 data-text className={styles.resultPhrase}>
                            {intl.formatMessage(messages.budget)}
                        </h3>
                        <form>
                            <label className={styles.resultTitle}>
                                <input
                                    min="0"
                                    max="1000000"
                                    className={styles.input}
                                    type="number"
                                    value={initialBudget || ''}
                                    onChange={onChangeInitialBudget}
                                />
                                <span className={styles.inputDollar}>$</span>
                            </label>
                        </form>
                        <div className={styles.budgetResultsContainer}>
                            <div
                                style={{
                                    opacity: `${budgetValue !== 50 ? '1' : '0'}`,
                                    transition: `opacity 0.5s ease-out`,
                                }}
                            >
                                <h3 data-text className={styles.budgetEconomy}>
                                    {budgetDiff >= 0
                                        ? intl.formatMessage(messages.budgetEconomy)
                                        : ''}
                                    {budgetDiff < 0 ? intl.formatMessage(messages.budgetDiff) : ''}
                                </h3>
                                <h3 className={styles.diff}>
                                    {Math.abs(budgetDiff)}
                                    <span>$</span>
                                </h3>
                                <h3 className={styles.resultPhrase}>Total</h3>
                                <h3 className={styles.amountResult}>
                                    {initialBudget - budgetDiff || 0}
                                    <span>$</span>
                                </h3>
                            </div>
                            <h3
                                data-text
                                className={classNames([styles.resultPhrase, styles.defaultPhrase])}
                                style={{
                                    opacity: `${budgetValue == 50 ? '1' : '0'}`,
                                }}
                            >
                                {intl.formatMessage(messages.budgetDefault)}
                            </h3>
                        </div>
                        <p
                            data-text
                            className={classNames([styles.resultDescription, styles.absolute])}
                            dangerouslySetInnerHTML={{ __html: budgetData.description }}
                        />
                    </div>
                </div>
            </div>
        </section>
    );
};

InsubordinatorSection.propTypes = propTypes;
InsubordinatorSection.defaultProps = defaultProps;

export default InsubordinatorSection;
