/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import styles from '../../styles/sections/we-are.module.scss';

const messages = defineMessages({
    title: {
        id: 'content.weAre',
        defaultMessage: `Nous sommes un atelier
            de création transdisciplinaire
            qui allie la force d’impact
            de l’art VISUEL et de la publicité avec la rigueur de la
            méthode journalistique.
        `,
    },
    desc: {
        id: 'content.weAreDesc',
        defaultMessage: `Nous créons des histoires multiformes et multi-plateformes.`,
    },
});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const WeAreSection = ({ className }) => {
    const intl = useIntl()
    return (
        <section className={classNames([styles.container, { [className]: className !== null }])}>
            <div className={styles.inner}>
                <h1 data-title className={styles.title}>{intl.formatMessage(messages.title)}</h1>
                <h2 data-text className={styles.desc}>{intl.formatMessage(messages.desc)}</h2>
            </div>
        </section>
    );
};

WeAreSection.propTypes = propTypes;
WeAreSection.defaultProps = defaultProps;

export default WeAreSection;
