const goldColor = '#b19554';

export default {
    home: {
        name: 'home',
        backgroundColor: '#000',
        withTrait: true,
    },
    insubordinator: {
        name: 'insubordinator',
        color: '#fff',
    },
    weAre: {
        name: 'we-are',
        backgroundColor: goldColor,
        withTrait: true,
    },
    weCreate: {
        name: 'we-create',
        backgroundColor: '#fff',
    },
    team: {
        name: 'team',
        backgroundColor: '#000',
        traitColor: goldColor,
        withTrait: true,
        type: 'trait',
    },
    projects: {
        name: 'projects',
        backgroundColor: goldColor,
    },
    contact: {
        name: 'contact',
        backgroundColor: '#fff',
        traitColor: goldColor,
        withTrait: true,
        type: 'trait',
    },
    phone: {
        name: 'phone',
        backgroundColor: '#000',
    },
    mail: {
        name: 'mail',
        backgroundColor: goldColor,
    },
    credits: {
        name: 'credits',
        backgroundColor: '#000',
    },
}
