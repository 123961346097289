import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const PlayIcon = ({ className, color }) => (
    <svg
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.36364 4.16L8.59182 8L2.36364 11.84V4.16ZM0 0V16L13 8L0 0Z"
            fill={color}
        />
    </svg>
);

PlayIcon.propTypes = propTypes;
PlayIcon.defaultProps = defaultProps;

export default PlayIcon;
