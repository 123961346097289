/* eslint-disable react/button-has-type */
import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { Link } from 'react-router-dom';

// import * as AppPropTypes from '../../lib/PropTypes';

import styles from '../../styles/buttons/burger-button.module.scss';

const propTypes = {
    open: PropTypes.bool,
    onClick: PropTypes.func.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    open: false,
    className: null,
};

const Button = ({ className, open, onClick }) => {
    const onOpenMenu = useCallback(
        e => {
            e.preventDefault();
            onClick(e);
        },
        [onClick, open],
    );
    return (
        <button
            type="button"
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles.open]: open },
            ])}
            onClick={onOpenMenu}
        >
            <div className={classNames([styles.layer, styles.top])} />
            <div className={classNames([styles.layer, styles.middle])} />
            <div className={classNames([styles.layer, styles.bottom])} />
        </button>
    );
};

Button.propTypes = propTypes;
Button.defaultProps = defaultProps;

export default Button;
