import React from 'react';
import ReactDOM from 'react-dom';
import domready from 'domready';
import defaultRootProps from './data/root';

import Root from './components/Root';

const getRootProps = () => defaultRootProps;

const needsPolyfill = () =>
    typeof window.Intl === 'undefined' || typeof window.IntersectionObserver === 'undefined';

const renderRoot = props => {
    const rootEl = document.getElementById('root');
    const root = React.createElement(Root, props);
    ReactDOM.render(root, rootEl);
};

const boot = () => {
    const rootProps = getRootProps();

    if (needsPolyfill()) {
        const { locale = 'fr' } = rootProps;
        import(`./vendor/polyfills/intl-${locale}`)
            .then(() => import(`./vendor/polyfills/intersection-observer`))
            .then(() => renderRoot(rootProps));
    } else {
        renderRoot(rootProps);
    }
};

const ready = (document.readyState || 'loading') !== 'loading';
if (ready) {
    boot();
} else {
    domready(boot);
}
