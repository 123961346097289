/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import useBackgroundInsubordinator from '../../lib/useBgInsubordinator';

import styles from '../../styles/partials/background-with-insubordination.module.scss';

const propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Background = ({ className, value }) => {
    const {
        imageUrl,
        backgroundClassName = null,
        backgroundColor = null,
        opacity,
        repeat = 'no-repeat',
        size = 'contain',
        duration = 2,
    } = useMemo(() => useBackgroundInsubordinator(value), [value]);

    return (
        <div
            className={classNames([styles.container, { [className]: className !== null }])}
        >
            <div
                className={classNames([styles.backgroundInner, styles[backgroundClassName]])}
                style={{
                    backgroundColor:
                        backgroundClassName !== null
                            ? backgroundColor
                            : '',
                    transition: `background-color 0.4s ease-in-out, opacity 0.4s ease-in-out`,
                    opacity,
                    backgroundImage: `url(${imageUrl})`,
                    backgroundRepeat: repeat,
                    backgroundSize: size,
                    backgroundPosition: 'center center',
                    animationDuration: `${duration}s`,
                }}
            />
        </div>
    );
};

Background.propTypes = propTypes;
Background.defaultProps = defaultProps;

export default Background;
