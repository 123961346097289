/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import LogoFolklore from '../logos/Folklore';
import LogoShort from '../logos/LogoShort';

import styles from '../../styles/sections/credits.module.scss';

const messages = defineMessages({
    title: {
        id: 'content.credits.title',
        defaultMessage: 'Insubordination a été fondée le 01 Janvier 2020',
    },
    subtitle: {
        id: 'content.credits.subtitle',
        defaultMessage: 'Ce site a été créé avec l’atelier ami Folklore',
    },
    tagline: {
        id: 'content.credits.tagline',
        defaultMessage:
            'Notre entreprise est québecoise mais notre insubordination est transculturelle',
    },
});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const FooterSection = ({ className }) => {
    const intl = useIntl();
    return (
        <section className={classNames([styles.container, { [className]: className !== null }])}>
            <div className={styles.inner}>
                <div className={styles.content}>
                    <h1 data-title className={styles.title}>
                        {intl.formatMessage(messages.title)}
                    </h1>
                    <h2 data-text className={styles.subtitle}>
                        {intl.formatMessage(messages.subtitle)}
                    </h2>
                    <div className={styles.logos}>
                        <a href="https://www.behance.net/Insubordination_IN" target="_blank" rel="noopener noreferrer">
                            <LogoShort color="#fff" />
                        </a>
                        <a href="https://folkloreinc.ca/" target="_blank" rel="noopener noreferrer">
                            <LogoFolklore color="#fff" />
                        </a>
                    </div>
                    <p data-text className={styles.tagline}>
                        {intl.formatMessage(messages.tagline)}
                    </p>
                </div>
            </div>
        </section>
    );
};

FooterSection.propTypes = propTypes;
FooterSection.defaultProps = defaultProps;

export default FooterSection;
