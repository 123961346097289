/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages } from 'react-intl';
import { useForm } from '@folklore/forms';

// import * as AppPropTypes from '../../lib/PropTypes';
import TextField from '../fields/Text';
import Button from '../buttons/Button';
import Label from '../partials/Label';

import styles from '../../styles/forms/simple-newsletter.module.scss';

const messages = defineMessages({
    description: {
        id: 'content.newsletter_description',
        defaultMessage:
            'Laissez-nous vos coordonnées<br/>pour suivre l’évolution<br/>de notre insubordination.',
    },
    email: {
        id: 'content.email_label',
        defaultMessage: 'Courriel',
    },
    button: {
        id: 'content.send_button',
        defaultMessage: 'Envoyer',
    },
});

const propTypes = {
    className: PropTypes.string,
    onComplete: PropTypes.func,
};

const defaultProps = {
    className: null,
    onComplete: null,
};

const Newsletter = ({ className }) => {
    const action =
        'https://insubordination.us4.list-manage.com/subscribe/post?u=ad56ab33d7496f3e75b579899&amp;id=7c0dffd7a3';
    const { fields, loading } = useForm({
        action,
        fields: ['EMAIL'],
    });
    return (
        <div
            className={classNames([
                styles.container,
                {
                    [className]: className !== null,
                },
            ])}
        >
            <form action={action} method="POST" target="_blank">
                <input
                    type="text"
                    name="b_ad56ab33d7496f3e75b579899_7c0dffd7a3"
                    tabIndex="-1"
                    value=""
                    readOnly
                    style={{ display: 'none' }}
                />
                <TextField
                    type="email"
                    label={messages.email}
                    inputClassName={styles.input}
                    {...fields.EMAIL}
                />
                <div className={styles.description}>
                    <Label isHtml>{messages.description}</Label>
                </div>
                <div className={styles.actions}>
                    <Button
                        type="submit"
                        label={messages.button}
                        className={styles.button}
                        disabled={loading}
                        loading={loading}
                        green
                    />
                </div>
            </form>
        </div>
    );
};

Newsletter.propTypes = propTypes;
Newsletter.defaultProps = defaultProps;

export default Newsletter;
