/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styles from '../../styles/partials/slideshow.module.scss';

const propTypes = {
    images: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string, credit: PropTypes.string })),
    delay: PropTypes.number,
    className: PropTypes.string,
};

const defaultProps = {
    images: null,
    delay: 2000, // miliseconds
    className: null,
};

const Slideshow = ({ images, delay, className }) => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(lastIndex => {
                if (lastIndex === images.length - 1) {
                    return 0;
                }
                return lastIndex + 1;
            });
        }, delay);

        return () => {
            clearInterval(interval);
        };
    }, [images, delay]);

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            {(images || []).map((image, imageIndex) => {
                const { url, credit = null } = image;
                return (
                    <div
                        key={`image-${imageIndex}`}
                        className={classNames([
                            styles.image,
                            { [styles.active]: index === imageIndex },
                        ])}
                        style={{ backgroundImage: `url(${url})` }}
                    >
                        {credit !== null ? (
                            <div className={styles.photoCredits}>
                                © {credit}
                            </div>
                        ) : null}
                    </div>
                );
            })}
        </div>
    );
};

Slideshow.propTypes = propTypes;
Slideshow.defaultProps = defaultProps;

export default Slideshow;
