/* eslint-disable react/jsx-props-no-spreading, no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { camelCase } from 'lodash';
import { Link } from 'react-router-dom';

import styles from '../../styles/menus/sidebar-menu.module.scss';

const messages = defineMessages({
    home: { id: 'content.homeLink', defaultMessage: 'Nous croyons' },
    insubordinator: { id: 'content.insubordinatorLink', defaultMessage: 'Insubordinateur' },
    weAre: { id: 'content.weAreLink', defaultMessage: 'Nous sommes' },
    weCreate: { id: 'content.createLink', defaultMessage: 'Nous créons' },
    team: { id: 'content.teamLink', defaultMessage: 'Équipe' },
    projects: { id: 'content.projectsLink', defaultMessage: 'Projets' },
    contact: { id: 'content.contactLink', defaultMessage: 'Coordonnées' },
    credits: { id: 'content.footerLink', defaultMessage: 'Inf' },
});

const propTypes = {
    menu: PropTypes.arrayOf(PropTypes.shape({ slug: PropTypes.string, url: PropTypes.string }))
        .isRequired,
    closeMenu: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    closeMenu: null,
    className: null,
};

const Navbar = ({ className, menu, closeMenu }) => {
    const intl = useIntl();
    const classStyles = menu.map((it, idx) => {
        const modulo = idx % 3;
        switch (modulo) {
            case 1:
                return styles.one;
            case 2:
                return styles.two;
            default:
                return null;
        }
    });

    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <ul className={styles.inner}>
                {menu.map((item, itemI) => {
                    const itemName = camelCase(item.slug);
                    return (
                        <Link key={item.url} to={`/${item.url}`} onClick={closeMenu}>
                            <li
                                key={itemName}
                                className={classNames([styles.list, classStyles[itemI]])}
                            >
                                {intl.formatMessage(messages[itemName])}
                            </li>
                        </Link>
                    );
                })}
            </ul>
        </div>
    );
};

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export default Navbar;
