import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const Pin = ({ className, color }) => (
    <svg
        width="58"
        height="56"
        viewBox="0 0 58 56"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M53.8884 37.8656C51.6663 40.2914 48.9704 42.236 45.9674 43.5792C43.0581 44.8824 39.9223 45.6049 36.7361 45.706C33.5302 45.8162 30.3332 45.307 27.3202 44.2063C20.9894 41.8852 15.8369 37.149 12.9919 31.0356C11.6423 28.1259 10.8665 24.9832 10.707 21.7798C10.5397 18.5961 10.9958 15.4105 12.0497 12.4017C13.1359 9.29638 14.847 6.44666 17.0775 4.02848C18.3113 2.68539 19.6924 1.48535 21.1945 0.450984C17.0139 8.75528 17.9657 24.1552 26.5 32C35.0343 39.8448 49.1844 38.3269 57.1073 33.4625C56.2028 35.0462 55.1231 36.5232 53.8884 37.8656Z"
            fill={color}
        />
    </svg>
);

Pin.propTypes = propTypes;
Pin.defaultProps = defaultProps;

export default Pin;
