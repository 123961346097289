import simon00a from './simon_00a.JPG';
import simon00b from './simon_00b.jpg';
import simon01a from './simon_01a.JPG';
import simon01b from './simon_01b.JPG';
import simon01c from './simon_01c.gif';
import simon01f from './simon_01f.JPG';
import simon01g from './simon_01g.JPG';
import simon02a from './simon_02a.JPG';
import simon02b from './simon_02b.JPG';
import simon02c from './simon_02c.JPG';
import simon02d from './simon_02d.JPG';
import simon03a from './simon_03a.JPG';
import simon03b from './simon_03b.JPG';
import simon03c from './simon_03c.JPG';
import simon03d from './simon_03d.JPG';
import simon03e from './simon_03e.gif';
import simon04a from './simon_04a.JPG';
import simon04b from './simon_04b.JPG';
import simon04c from './simon_04c.JPG';
import simon04d from './simon_04d.JPG';
import simon04e from './simon_04e.JPG';
import simon05a from './simon_05a.jpg';
import simon05b from './simon_05b.JPG';
// import simon06a from './simon_06a.JPG';
import simon07a from './simon_07a.JPG';
import simon08a from './simon_08a.gif';
import simon08b from './simon_08b.JPG';
import simon09a from './simon_09a.JPG';
import simon09b from './simon_09b.JPG';
import simon09c from './simon_09c.gif';
import simon10a from './simon_10a.JPG';
import simon10b from './simon_10b.JPG';
import simon11a from './simon_11a.JPG';
import simon11b from './simon_11b.JPG';
import human2 from '../insubordinator/human-2.gif';

export default [
    [simon00a, simon00b],
    [simon01a, simon01b, simon01c, simon01f, simon01g],
    [simon02a, simon02b, simon02c, simon02d],
    [simon03a, simon03b, simon03c, simon03d, simon03e],
    [simon04a, simon04b, simon04c, simon04d, simon04e],
    [simon05a, simon05b],
    // [simon06a],
    [simon07a],
    [simon08a, simon08b],
    [simon09a, simon09b, simon09c],
    [simon10a, simon10b],
    [simon11a, simon11b, human2],
];
