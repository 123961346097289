import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const LogoLong = ({ className, color }) => (
    <svg
        width="524"
        height="48"
        viewBox="0 0 524 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M56 31.9L64.1 31C64.6 34 65.6 36.3 67.1 37.7C68.6 39.1 70.6 39.8 73.2 39.8C75.9 39.8 77.9 39.2 79.3 37.9C80.7 36.6 81.4 35.1 81.4 33.5C81.4 32.4 81.1 31.5 80.5 30.7C79.9 29.9 78.9 29.3 77.5 28.7C76.5 28.3 74.3 27.7 70.8 26.7C66.3 25.5 63.2 23.9 61.4 22.1C58.9 19.6 57.6 16.5 57.6 12.8C57.6 10.5 58.2 8.29999 59.4 6.19999C60.6 4.09999 62.3 2.59999 64.6 1.49999C66.9 0.399994 69.6 -0.100006 72.8 -0.100006C78 -0.100006 81.9 1.19999 84.6 3.69999C87.2 6.19999 88.6 9.59999 88.7 13.9L80.3 14.3C79.9 11.9 79.2 10.2 78 9.19999C76.8 8.19999 75.1 7.59999 72.7 7.59999C70.3 7.59999 68.4 8.19999 67 9.29999C66.1 9.99999 65.7 11 65.7 12.2C65.7 13.3 66.1 14.2 66.9 15C68 16 70.5 17 74.6 18.1C78.7 19.2 81.7 20.3 83.6 21.4C85.6 22.5 87.1 24.1 88.2 26.1C89.3 28.1 89.9 30.5 89.9 33.4C89.9 36 89.2 38.5 87.9 40.8C86.6 43.1 84.7 44.8 82.3 45.9C79.9 47 76.9 47.6 73.3 47.6C68.1 47.6 64 46.3 61.2 43.6C58.2 41 56.5 37 56 31.9Z"
            fill="white"
        />
        <path
            d="M95.7998 0.800003H104.2V25.8C104.2 29.8 104.3 32.3 104.5 33.5C104.9 35.4 105.7 36.9 107.1 38C108.4 39.1 110.3 39.7 112.6 39.7C115 39.7 116.8 39.2 118 38.1C119.2 37 119.9 35.7 120.2 34.1C120.4 32.5 120.6 29.9 120.6 26.3V0.800003H129V25C129 30.5 128.8 34.4 128.3 36.7C127.8 39 127 40.9 125.8 42.5C124.6 44.1 123 45.3 120.9 46.3C118.9 47.2 116.2 47.7 112.9 47.7C108.9 47.7 105.9 47.2 103.9 46.2C101.9 45.2 100.2 43.9 98.9998 42.2C97.7998 40.6 96.9998 38.9 96.6998 37.1C96.1998 34.5 95.8998 30.6 95.8998 25.4V0.800003H95.7998Z"
            fill="white"
        />
        <path
            d="M136.9 0.800003H153.5C156.8 0.800003 159.2 1 160.8 1.3C162.4 1.6 163.9 2.2 165.1 3.2C166.4 4.2 167.4 5.4 168.3 7.1C169.1 8.7 169.6 10.5 169.6 12.5C169.6 14.7 169.1 16.6 168 18.4C167 20.2 165.5 21.6 163.7 22.5C166.2 23.3 168.2 24.7 169.5 26.7C170.9 28.7 171.5 31 171.5 33.6C171.5 35.7 171.1 37.7 170.2 39.7C169.3 41.7 168.1 43.3 166.6 44.4C165.1 45.6 163.2 46.3 161 46.6C159.6 46.8 156.2 46.9 150.9 46.9H136.9V0.800003ZM145.3 8.5V19.2H150.8C154.1 19.2 156.1 19.1 156.9 19C158.3 18.8 159.5 18.3 160.3 17.3C161.1 16.4 161.5 15.2 161.5 13.7C161.5 12.3 161.1 11.1 160.4 10.2C159.7 9.3 158.6 8.8 157.2 8.6C156.4 8.5 154 8.4 150 8.4H145.3V8.5ZM145.3 26.8V39.1H153C156 39.1 157.9 39 158.7 38.8C159.9 38.5 161 37.9 161.7 37C162.5 36 162.9 34.7 162.9 33.1C162.9 31.7 162.6 30.5 162 29.6C161.4 28.6 160.5 27.9 159.4 27.5C158.3 27.1 155.8 26.8 152 26.8H145.3V26.8Z"
            fill="white"
        />
        <path
            d="M176.1 24.1C176.1 19.4 176.7 15.5 178 12.3C178.9 10 180.2 7.80001 181.9 6.00001C183.5 4.10001 185.3 2.80001 187.3 1.90001C189.9 0.700006 192.9 0.100006 196.2 0.100006C202.3 0.100006 207.2 2.20001 210.9 6.40001C214.6 10.6 216.4 16.5 216.4 24C216.4 31.4 214.6 37.3 210.9 41.5C207.3 45.7 202.4 47.8 196.3 47.8C190.1 47.8 185.2 45.7 181.6 41.5C178 37.2 176.1 31.4 176.1 24.1ZM184.8 23.8C184.8 29 185.9 33 188.1 35.7C190.3 38.4 193 39.7 196.4 39.7C199.7 39.7 202.5 38.4 204.6 35.7C206.7 33 207.8 29 207.8 23.7C207.8 18.4 206.8 14.5 204.7 11.9C202.6 9.30001 199.8 8.00001 196.4 8.00001C192.9 8.00001 190.2 9.30001 188.1 11.9C185.8 14.5 184.8 18.5 184.8 23.8Z"
            fill="white"
        />
        <path
            d="M222.1 46.9V0.800003H239.7C244.1 0.800003 247.4 1.2 249.4 2C251.4 2.8 253 4.3 254.2 6.4C255.4 8.5 256 10.9 256 13.7C256 17.2 255.1 20 253.3 22.3C251.5 24.6 248.7 26 245.1 26.6C246.9 27.8 248.4 29.1 249.6 30.5C250.8 31.9 252.4 34.4 254.4 38L259.5 47H249.5L243.4 37C241.2 33.4 239.8 31.2 239 30.2C238.2 29.3 237.4 28.6 236.5 28.3C235.6 28 234.2 27.8 232.3 27.8H230.6V47H222.1V46.9ZM230.4 20.3H236.6C240.6 20.3 243.1 20.1 244.1 19.7C245.1 19.3 245.9 18.7 246.4 17.8C247 16.9 247.2 15.7 247.2 14.3C247.2 12.8 246.8 11.5 246.1 10.5C245.4 9.5 244.3 8.9 242.9 8.7C242.2 8.6 240.2 8.5 236.8 8.5H230.3V20.3H230.4Z"
            fill="white"
        />
        <path
            d="M263 0.800003H278.3C281.8 0.800003 284.4 1.1 286.2 1.7C288.6 2.5 290.7 3.9 292.5 5.9C294.2 7.9 295.6 10.4 296.5 13.4C297.4 16.3 297.9 20 297.9 24.3C297.9 28.1 297.5 31.4 296.6 34.1C295.6 37.5 294.1 40.2 292.2 42.2C290.7 43.8 288.8 45 286.3 45.9C284.5 46.5 282 46.9 278.9 46.9H263V0.800003ZM271.4 8.6V39.1H277.7C280 39.1 281.7 39 282.8 38.7C284.2 38.3 285.3 37.7 286.2 36.8C287.1 35.9 287.8 34.4 288.4 32.4C289 30.3 289.2 27.5 289.2 24C289.2 20.4 288.9 17.7 288.4 15.8C287.8 13.9 287 12.4 286 11.3C285 10.2 283.7 9.5 282.1 9.1C280.9 8.8 278.6 8.7 275.2 8.7H271.4V8.6Z"
            fill="white"
        />
        <path d="M312.1 0.800003H303.7V46.9H312.1V0.800003Z" fill="white" />
        <path
            d="M319.3 46.9V0.800003H327.4L344.4 31.6V0.800003H352.2V46.9H343.8L327.1 16.8V46.9H319.3Z"
            fill="white"
        />
        <path
            d="M397.6 46.9H388.5L384.9 36.4H368.3L364.9 46.9H356L372.2 0.800003H381.1L397.6 46.9ZM382.2 28.6L376.5 11.5L370.9 28.6H382.2Z"
            fill="white"
        />
        <path d="M406.2 46.9V8.6H393.9V0.800003H426.8V8.6H414.6V46.9H406.2Z" fill="white" />
        <path d="M439.5 0.800003H431.1V46.9H439.5V0.800003Z" fill="white" />
        <path
            d="M444.9 24.1C444.9 19.4 445.5 15.5 446.8 12.3C447.7 10 449 7.80001 450.7 6.00001C452.3 4.10001 454.1 2.80001 456.1 1.90001C458.7 0.700006 461.7 0.100006 465 0.100006C471.1 0.100006 476 2.20001 479.7 6.40001C483.4 10.6 485.2 16.5 485.2 24C485.2 31.4 483.4 37.3 479.7 41.5C476.1 45.7 471.2 47.8 465.1 47.8C458.9 47.8 454 45.7 450.4 41.5C446.7 37.2 444.9 31.4 444.9 24.1ZM453.5 23.8C453.5 29 454.6 33 456.8 35.7C459 38.4 461.7 39.7 465.1 39.7C468.4 39.7 471.2 38.4 473.3 35.7C475.4 33 476.5 29 476.5 23.7C476.5 18.4 475.5 14.5 473.4 11.9C471.3 9.30001 468.5 8.00001 465.1 8.00001C461.6 8.00001 458.9 9.30001 456.8 11.9C454.6 14.5 453.5 18.5 453.5 23.8Z"
            fill="white"
        />
        <path
            d="M490.9 46.9V0.800003H499L516 31.6V0.800003H523.8V46.9H515.4L498.7 16.8V46.9H490.9Z"
            fill="white"
        />
        <path d="M9.9 1H0V46.8H9.9V1Z" fill="white" />
        <path d="M29.6998 1H19.7998V46.8H29.6998V1Z" fill="white" />
        <path d="M49.4 1H39.5V46.8H49.4V1Z" fill="white" />
        <path d="M29.6998 1H19.7998L39.4998 46.8H49.3998L29.6998 1Z" fill={color} />
    </svg>
);

LogoLong.propTypes = propTypes;
LogoLong.defaultProps = defaultProps;

export default LogoLong;
