import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
    muted: PropTypes.bool,
};

const defaultProps = {
    className: null,
    color: '#B19554',
    muted: false,
};

const AudioIcon = ({ className, color, muted }) => (
    <svg
        width="18"
        height="16"
        viewBox="0 0 18 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            d="M11.6842 0.0629205C11.5263 -0.0525119 11.3158 0.00520423 11.1579 0.120637L6.05263 4.16077H2.52632C2.21053 4.16077 2 4.39164 2 4.73793V10.5096C2 10.8559 2.21053 11.0867 2.52632 11.0867H6.05263L11.1579 15.1846C11.2632 15.2423 11.3684 15.3 11.4737 15.3C11.5789 15.3 11.6316 15.3 11.7368 15.2423C11.8947 15.1269 12 14.9537 12 14.7228V0.582366C12 0.351501 11.8947 0.120637 11.6842 0.0629205Z"
            fill="white"
        />
        {muted ? (
            <path d="M0 9.98649L0 14L16 6.01351V2L0 9.98649Z" fill={color} />
        ) : (
            <g>
                <path
                    d="M16.1087 3.19286C15.8478 2.93571 15.4565 2.93571 15.1957 3.19286C14.9348 3.45 14.9348 3.83571 15.1957 4.09286C16.1739 4.99286 16.6957 6.21429 16.6957 7.5C16.6957 8.78571 16.1739 10.0071 15.1957 10.9071C14.9348 11.1643 14.9348 11.55 15.1957 11.8071C15.3261 11.9357 15.5217 12 15.6522 12C15.7826 12 15.9783 11.9357 16.1087 11.8071C17.3478 10.65 18 9.17143 18 7.5C18 5.82857 17.3478 4.35 16.1087 3.19286Z"
                    fill={color}
                />
                <path
                    d="M14.049 5.19794C13.7954 4.92354 13.415 4.92354 13.1614 5.26654C12.9078 5.54095 12.9712 5.95255 13.2248 6.22696C13.5418 6.56996 13.732 7.05017 13.732 7.53037C13.732 8.01058 13.5418 8.49078 13.2248 8.83379C12.9712 9.10819 12.9712 9.51979 13.1614 9.7942C13.2882 9.9314 13.4784 10 13.6052 10C13.732 10 13.9222 9.9314 14.049 9.7942C14.683 9.17679 15 8.35358 15 7.53037C15 6.70716 14.6196 5.74675 14.049 5.19794Z"
                    fill={color}
                />
            </g>
        )}
    </svg>
);

AudioIcon.propTypes = propTypes;
AudioIcon.defaultProps = defaultProps;

export default AudioIcon;
