/* eslint-disable react/jsx-props-no-spreading, react/no-array-index-key, jsx-a11y/media-has-caption */
import React, { useCallback, useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import { useIntersectionObserver } from '../../lib/useObserver';
import projectsVideo from '../../videos/creations.mp4';
import projectsVideoWebm from '../../videos/creations.webm';
import AudioIcon from '../icons/Audio';
import Play from '../icons/Play';
import Pause from '../icons/Pause';
import Slideshow from '../partials/Slideshow';

import imgSmile from '../../img/insubordinator/wierd-smile.jpg';
import imgPlane from '../../img/insubordinator/plane.jpg';
import imgCake from '../../img/insubordinator/cake.jpg';
import imgHorror from '../../img/insubordinator/horror.jpg';
import imgLooneyTunes from '../../img/insubordinator/looney-tounes.gif';
import imgMagicFlower from '../../img/insubordinator/magic-flower.gif';

import styles from '../../styles/sections/we-create.module.scss';

const images = {
    smile: {
        url: imgSmile,
        credit: "Nomadic Samuel"
    },
    plane: {
        url: imgPlane,
    },
    cake: {
        url: imgCake,
        credit: "Becka Heikkila"
    },
    horror: {
        url: imgHorror,
        credit: "Rasen Kaigan"
    },
    tunes: {
        url: imgLooneyTunes,
    },
    flower: {
        url: imgMagicFlower,
    }
}

const messages = defineMessages({
    line1: {
        id: 'content.line1',
        defaultMessage: 'Pour celles et ceux qui croient',
    },
    line2: {
        id: 'content.line2',
        defaultMessage: 'Tout est possible',
    },
    line3: {
        id: 'content.line3',
        defaultMessage: 'Fuck the world',
    },
    line4: {
        id: 'content.line4',
        defaultMessage: 'Contenu libre',
    },
    line5: {
        id: 'content.line5',
        defaultMessage: 'Contenu de marque',
    },
    line6: {
        id: 'content.line6',
        defaultMessage: 'All in',
    },
    line7: {
        id: 'content.line7',
        defaultMessage: 'Courage',
    },
    line8: {
        id: 'content.line8',
        defaultMessage: 'Intuition',
    },
    line9: {
        id: 'content.line9',
        defaultMessage: 'Curiosité',
    },
});

const videoMessage = defineMessages({
    video: {
        id: 'content.videoCredits',
        defaultMessage: 'Pour les crédits, c’est par ici.',
    },
});

const propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const CreateSection = ({ value, className }) => {
    const intl = useIntl();
    const [messageReferences, setMessagesReferences] = useState(Object.keys(messages));
    const [randomTopMessage, setRandomTopMessage] = useState('line4');
    const [randomBottomMessage, setRandomBottomMessage] = useState('line8');
    const [muted, setMuted] = useState(true);
    const [paused, setPaused] = useState(true);
    const videoRef = useRef(null);
    const intervalRef = useRef({});

    const randomizeMessages = useCallback(() => {
        if (messageReferences.length <= 1) {
            setMessagesReferences(Object.keys(messages));
        } else {
            setRandomTopMessage(
                messageReferences.splice(Math.floor(Math.random() * messageReferences.length), 1),
            );
            setRandomBottomMessage(
                messageReferences.splice(
                    Math.floor(Math.random() * messageReferences.length - 1),
                    1,
                ),
            );
        }
    }, [messageReferences]);

    useEffect(() => {
        intervalRef.current = setInterval(() => randomizeMessages(), 3000);
        return () => clearInterval(intervalRef.current);
    }, [messageReferences]);

    const onToggleMute = useCallback(() => {
        setMuted(!muted);
    }, [setMuted, muted]);

    const onTogglePlay = useCallback(() => {
        if (paused) {
            videoRef.current.play();
            setPaused(false);
        } else {
            videoRef.current.pause();
            setPaused(true);
        }
    }, [setPaused, paused, videoRef]);

    const {
        ref,
        entry: { isIntersecting = false },
    } = useIntersectionObserver({ rootMargin: '-5%' });

    useEffect(() => {
        if (isIntersecting && paused) {
            onTogglePlay();
        }
        if (!isIntersecting && !paused) {
            onTogglePlay();
        }
    }, [isIntersecting]);

    const showSlideshow = value > 70;
    const slideshowImages = value > 85 ? [images.smile, images.plane, images.tunes] : [images.cake, images.plane, images.flower];

    return (
        <section
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles.showSlideshow]: showSlideshow },
            ])}
            ref={ref}
        >
            <div className={styles.inner}>
                <h1 data-title className={styles.title}>
                    {intl.formatMessage(messages[randomTopMessage])}
                </h1>
                <div className={styles.videoContainer}>
                    <video
                        ref={videoRef}
                        className={styles.projectsVideo}
                        muted={muted}
                        loop
                        playsInline
                    >
                        <source src={projectsVideo} type="video/mp4" />
                        <source src={projectsVideoWebm} type="video/webm" />
                    </video>
                    <button
                        type="button"
                        className={classNames([styles.playLightBox, { [styles.playing]: !paused }])}
                        onClick={onTogglePlay}
                    >
                        {paused ? (
                            <div className={styles.playPause}>
                                <Play />
                            </div>
                        ) : (
                            <div className={styles.playPause}>
                                <Pause />
                            </div>
                        )}
                    </button>
                    <button className={styles.button} type="button" onClick={onToggleMute}>
                        <AudioIcon muted={muted} />
                    </button>
                    <Slideshow className={styles.slideshow} images={slideshowImages} />
                </div>
                <a
                    href="https://youtu.be/Jp_T-UdrTyE"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <h1 data-text className={styles.videoCredits}>
                        {intl.formatMessage(videoMessage.video)}
                    </h1>
                </a>
                <h1 data-title className={classNames([styles.title, styles.bottom])}>
                    {intl.formatMessage(messages[randomBottomMessage])}
                </h1>
            </div>
        </section>
    );
};

CreateSection.propTypes = propTypes;
CreateSection.defaultProps = defaultProps;

export default CreateSection;
