/* eslint-disable no-plusplus, prefer-destructuring */
import { getRelativeValue } from './utils';

const initialPoemData = [
    {
        type: 'sentence',
        verse: false,
        text: [
            {
                type: 'word',
                text: 'Ah',
            },
        ],
        punctuation: '!',
    },
    {
        type: 'sentence',
        verse: true,
        punctuation: '!',
        text: [
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'com',
                    },
                    {
                        type: 'syllabe',
                        text: 'me',
                    },
                ],
            },
            {
                type: 'word',
                text: 'la',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'nei',
                    },
                    {
                        type: 'syllabe',
                        text: 'ge',
                    },
                ],
            },
            {
                type: 'word',
                text: 'a',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'nei',
                    },
                    {
                        type: 'syllabe',
                        text: 'gé',
                    },
                ],
            },
        ],
    },
    {
        type: 'sentence',
        punctuation: '.',
        verse: true,
        text: [
            {
                type: 'word',
                text: 'Ma',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'vi',
                    },
                    {
                        type: 'syllabe',
                        text: 'tre',
                    },
                ],
            },
            {
                type: 'word',
                text: 'est',
            },
            {
                type: 'word',
                text: 'un',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'jar',
                    },
                    {
                        type: 'syllabe',
                        text: 'din',
                    },
                ],
            },
            {
                type: 'word',
                text: 'de',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'gi',
                    },
                    {
                        type: 'syllabe',
                        text: 'vre',
                    },
                ],
            },
        ],
    },
    {
        type: 'sentence',
        verse: false,
        text: [
            {
                type: 'word',
                text: 'Ah',
            },
        ],
        punctuation: '!',
    },
    {
        type: 'sentence',
        punctuation: '!',
        verse: true,
        text: [
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'com',
                    },
                    {
                        type: 'syllabe',
                        text: 'me',
                    },
                ],
            },
            {
                type: 'word',
                text: 'la',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'nei',
                    },
                    {
                        type: 'syllabe',
                        text: 'ge',
                    },
                ],
            },
            {
                type: 'word',
                text: 'a',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'nei',
                    },
                    {
                        type: 'syllabe',
                        text: 'gé',
                    },
                ],
            },
        ],
    },
    {
        type: 'sentence',
        punctuation: '?',
        verse: true,
        text: [
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'Qu’est-',
                    },
                    {
                        type: 'syllabe',
                        text: 'ce',
                    },
                ],
            },
            {
                type: 'word',
                text: 'que',
            },

            {
                type: 'word',
                text: 'le',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'spa',
                    },
                    {
                        type: 'syllabe',
                        text: 'sme',
                    },
                ],
            },
            {
                type: 'word',
                text: 'de',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'vi',
                    },
                    {
                        type: 'syllabe',
                        text: 'vre',
                    },
                ],
            },
        ],
    },
    {
        type: 'sentence',
        punctuation: '!',
        verse: true,
        text: [
            {
                type: 'word',
                text: 'À',
            },
            {
                type: 'word',
                text: 'la',
            },
            {
                type: 'word',
                text: [
                    {
                        type: 'syllabe',
                        text: 'dou',
                    },
                    {
                        type: 'syllabe',
                        text: 'leur',
                    },
                ],
            },
            {
                type: 'word',
                text: 'que',
            },
            {
                type: 'word',
                text: 'j’ai,',
            },
            {
                type: 'word',
                text: 'que',
            },
            {
                type: 'word',
                text: 'j’ai',
            },
        ],
    },
];

const shuffleArray = (myArr, factor) => {
    const numberOfElementsToMove = Math.round(myArr.length * factor);
    const arrayCopy = [...myArr];
    const arrayTemp = [];
    
    for (let i = 0; i < numberOfElementsToMove; i++) {
        const rndm = Math.floor(Math.random() * arrayCopy.length);
        arrayTemp.push(arrayCopy.splice(rndm, 1)[0]);
    }

    const arrayFinal = [...arrayCopy, ...arrayTemp];
    return arrayFinal;
};

const shufflers = [
    {
        type: 'sentence',
        start: 0,
        end: 100,
        shuffle: (data, value, relativeValue) => shuffleArray(data, relativeValue),
        ratio: 100,
    },
    {
        type: 'word',
        start: 25,
        end: 100,
        shuffle: (data, value, relativeValue) =>
            data.reduce((newData, words) => {
                const toInsert = { ...words, text: shuffleArray(words.text, relativeValue) };
                return [...newData, toInsert];
            }, []),
        ratio: 75,
    },
    {
        type: 'syllabe',
        start: 50,
        end: 100,
        shuffle: (data, value, relativeValue) =>
            data.reduce((newData, words) => {
                const toInsert = {
                    ...words,
                    text: words.text.reduce((wordsData, syllabes) => {
                        let newSyllabes = syllabes.text;
                        if (Array.isArray(newSyllabes)) {
                            newSyllabes = shuffleArray(syllabes.text, relativeValue);
                        }
                        const syllsToInsert = {
                            ...syllabes,
                            text: newSyllabes,
                        };
                        return [...wordsData, syllsToInsert];
                    }, []),
                };
                return [...newData, toInsert];
            }, []),
        ratio: 50,
    },
    {
        type: 'letter',
        start: 75,
        end: 100,
        shuffle: (data, value, relativeValue) =>
            data.reduce((newData, words) => {
                const toInsert = {
                    ...words,
                    text: words.text.reduce((wordsData, syllabes) => {
                        let newSyllabes = syllabes.text;
                        if (Array.isArray(newSyllabes)) {
                            newSyllabes = syllabes.text.map(syll => {
                                return {
                                    type: syll.type,
                                    text: shuffleArray(syll.text.split(''), relativeValue).join(''),
                                };
                            });
                        } else {
                            newSyllabes = shuffleArray(newSyllabes.split(''), relativeValue).join(
                                '',
                            );
                        }
                        const syllsToInsert = {
                            ...syllabes,
                            text: newSyllabes,
                        };
                        return [...wordsData, syllsToInsert];
                    }, []),
                };
                return [...newData, toInsert];
            }, []),
        ratio: 25,
    },
];

export default value => {
    const relativeValue = getRelativeValue(value, 25);

    const shuffledParts = shufflers.reduce((newData, shuffler) => {
        if (value >= shuffler.start && value <= shuffler.end) {
            const maxRelativeValue = value >= (shuffler.start + 25) ? 1 : relativeValue;
            return shuffler.shuffle(newData, value, maxRelativeValue);
        }
        return newData;
    }, initialPoemData);

    const makeText = parts =>
        parts.reduce((text, part) => {
            if (part.type === 'sentence') {
                return `${text}${text.length > 0 ? ' ' : ''}${makeText(part.text)}${
                    part.punctuation
                }${part.verse ? '<br/>' : ''}`;
            }
            if (part.type === 'word') {
                return `${text}${text.length > 0 ? ' ' : ''}${
                    Array.isArray(part.text) ? makeText(part.text) : part.text
                }`;
            }
            return `${text}${part.text}`;
        }, '');

    return makeText(shuffledParts);
};
