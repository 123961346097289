import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const ReloadIcon = ({ className, color }) => (
    <svg
        width="124" height="124" viewBox="0 0 124 124" fill="none" xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M107.963 21.3965C102.453 15.1859 95.7446 10.1524 88.241 6.59793C84.5763 4.86277 80.7475 3.49767 76.8117 2.52293C64.9389 -0.420243 52.4577 0.247708 40.9669 4.44123C29.476 8.63475 19.4984 16.1629 12.3122 26.0616C5.12587 35.9603 1.05799 47.7788 0.629513 60.0034C0.201036 72.2281 3.43147 84.3024 9.90707 94.6799L14.0387 92.0964C13.4028 90.7081 12.7869 89.3232 12.2409 87.9182L11.8347 86.8628C11.7048 86.5099 11.585 86.1504 11.4618 85.7975C11.2055 85.0917 10.9891 84.3726 10.7727 83.6601C8.1836 75.2444 7.58301 66.3426 9.01815 57.6553C9.47072 54.8523 10.1476 52.0901 11.0423 49.3954C11.9313 46.7396 13.0163 44.1534 14.2883 41.6583C16.8269 36.7527 20.1109 32.2705 24.0231 28.3713C27.8641 24.5499 32.2759 21.3488 37.1004 18.8829C41.8338 16.47 46.9159 14.813 52.1619 13.9722C54.7622 13.585 57.3866 13.3814 60.0156 13.363L61.9832 13.3863C62.639 13.4262 63.2949 13.4429 63.9475 13.5061C65.2525 13.5994 66.5476 13.7658 67.8327 13.9656C68.4752 14.0621 69.1178 14.172 69.7537 14.2985L63.0452 25.4881L107.963 21.3965Z" fill={color}/>
        <path d="M73.8608 122.099C68.9673 123.067 63.9743 123.438 58.9915 123.202C54.9415 123.008 50.921 122.41 46.99 121.416C38.9407 119.381 31.3854 115.742 24.7773 110.715L34.7944 107.772L34.7977 107.773L68.0541 98.0061L63.6376 110.282C64.2855 110.284 64.9358 110.269 65.5888 110.238C66.8863 110.187 68.1893 110.097 69.4874 109.937C70.14 109.871 70.7884 109.764 71.4396 109.674L73.3725 109.316C75.9481 108.79 78.4834 108.083 80.9595 107.2C85.9438 105.359 90.6097 102.75 94.7884 99.4673C99.0432 96.113 102.751 92.1185 105.781 87.6265C108.865 83.0446 111.22 78.0117 112.761 72.7075C113.524 70.0127 114.088 67.2653 114.448 64.4877C114.756 62.0997 114.893 59.6925 114.856 57.2849C114.853 56.8545 114.843 56.4258 114.834 55.9939C114.727 51.9951 114.182 48.0201 113.21 44.1398L120.018 41.7405C122.887 50.0403 123.944 58.8587 123.117 67.6015C122.29 76.3443 119.599 84.8083 115.226 92.4233C110.852 100.038 104.896 106.627 97.7609 111.746C90.6255 116.866 82.4758 120.396 73.8608 122.099L73.8608 122.099Z" fill={color}/>
    </svg>
);

ReloadIcon.propTypes = propTypes;
ReloadIcon.defaultProps = defaultProps;

export default ReloadIcon;
