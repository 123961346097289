export const ourSiteData = [
    {
        title: 'Straight pipe',
        description:
            'Selon nous, il est possible d’être pas mal plus éclaté que ça. Bien qu’on aime repousser les limites, c’est vrai que c’est super direct. Parfois, ça suffit.',
    },
    {
        title: 'L’éprouvette',
        description:
            'Le scientifique en vous désire y aller à petites doses avant de prendre une décision aussi importante. On est patient, prenez votre temps.',
    },
    {
        title: 'Je ne sais pas',
        description:
            'Dans un sondage, vous répondez pratiquement toujours entre les extrêmes. Ça donne souvent un résultat tiédasse, mais confortable. Au moins, vous ne vous brûlez pas.',
    },
    {
        title: 'Le bouton détaché',
        description:
            'La capitaine que vous êtes n’a plus qu’une main sur le gouvernail. Vous sentez le vent de l’insubordination s’infiltrer. Vous bombez le torse et commencez à mieux respirer.',
    },
    {
        title: 'Merengue',
        description:
            'Là, on commence à jaser. C’est l’équivalent de se laisser tomber dans les bras de Patrick Swayze en sachant que le risque qu’il vous échappe est pratiquement nul.',
    },
    {
        title: 'Pit de sable',
        description:
            'À ce niveau, la terre commence à trembler et les repères deviennent plus flous. vous venez de vous cogner la tête et vous voyez des étoiles.',
    },
    {
        title: 'Sur la fesse',
        description:
            'C’est la note de passage. le passage entre la raison et la folie. Un brin de folie n’a jamais tué personne.',
    },
    {
        title: 'L’intersidéral',
        description:
            'Plus besoin de vous cogner la tête pour voir des étoiles. Vous êtes une étoile filante. Vous n’avez plus d’emprise sur tout ce qui défile sous vos yeux.',
    },
    {
        title: 'Champignon magique',
        description:
            'Rien de ce que vous voyez n’a de sens et vous ne détestez pas ça. Le beau, le laid, ces concepts ne tiennent plus. Vous vous laissez flotter.',
    },
    {
        title: 'saut de l’ange',
        description:
            'Twitter, Facebook, Instagram… Vous faites complètement confiance aux algorithmes qui choisissent ce que vous devriez voir ou non. Même nous ne contrôlons plus rien.',
    },
    {
        title: 'Réveillez les morts',
        description:
            'ARMAGEDDON. Vous avez dépassé les limites et êtes tombé dans un trou noir qui vous fait regretter votre sage subordination. Parfois, c’est trop.',
    },
];

export const poetryData = [
    {
        title: 'Le fidèle-à-l’auteur',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'Le petit spasme',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'L’anacoluthe',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'L’hyperbate',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'Le grand spasme',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'Le spleen profond',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'La contrepèterie',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'Le sibyllin',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },

    {
        title: 'L’exploréen',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'Le Gauvreau Plus Ultra',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
    {
        title: 'L’illisible-elbisilli’L',
        author: "Soir d'hiver / Émile Nelligan",
        description: `
            'Ah ! comme la neige a neigé !<br />
            Ma vitre est un jardin de givre.<br />
            Ah ! comme la neige a neigé !<br />
            Qu’est-ce que le spasme de vivre<br />
            À la douleur que j’ai, que j’ai !<br />
        `,
    },
];

export const budgetData = {
    description: `Plutôt que ces chiffres fictifs, retenez cette philosophie d’affaires toute simple: plus vous voulez contrôler la création, plus le projet vous coûtera cher. À l’inverse, plus vous êtes prêt à laisser de liberté créative, plus le coût du projet sera moindre. Naïf comme ça. Un brin de jasette supplémentaire pourra dissiper vos doutes.`,
};
