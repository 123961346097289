import { getRelativeValue } from './utils';

const useInsubordinator = siteValue => {
    let globalFilter;
    let globalAnimation = {};
    let sectionClassName = '';
    const relativeValue = getRelativeValue(siteValue);

    if (siteValue < 10) {
        globalFilter = { filter: `grayscale(${1 - relativeValue})` };
        globalAnimation = {};
        sectionClassName = 'straight';
    }
    if (siteValue >= 10 && siteValue < 20) {
        globalFilter = { filter: `contrast(${200 * relativeValue + 100}%)` };
        globalAnimation = {};
    }
    if (siteValue >= 20 && siteValue < 40) {
        globalFilter = { filter: `contrast(${280}%)` }
        globalAnimation = {};
    }
    if (siteValue >= 30 && siteValue < 40) {
        globalFilter = { filter: `hue-rotate(${relativeValue * 125}deg)` };
        globalAnimation = {};
    }
    if (siteValue >= 40 && siteValue < 50) {
        globalFilter = {};
        globalAnimation = {};
    }
    if (siteValue >= 50 && siteValue < 60) {
        globalFilter = { filter: `blur(${relativeValue * 3}px)` };
        globalAnimation = {};
    }
    if (siteValue >= 60 && siteValue < 70) {
        globalFilter = { filter: `invert(${relativeValue}) blur(${1}px)` }
        sectionClassName = 'blurAnimation';
    }
    if (siteValue >= 70 && siteValue < 80) {
        // globalFilter = { filter: `invert(${1 - relativeValue})` }
        sectionClassName = 'blurAnimation';
        globalAnimation = {};
    }
    if (siteValue >= 80 && siteValue < 90) {
        // globalFilter = { filter: `invert(${1}) blur(${2}px)` }
        // sectionClassName = 'blurAnimation';
    }
    if (siteValue >= 90 && siteValue < 97) {
        globalFilter = {};
        globalAnimation = {};
    }
    if (siteValue >= 97) {
        sectionClassName = 'blurAnimation';
        globalAnimation = {};
    }
    return {
        globalFilter,
        globalAnimation,
        sectionClassName,
    };
};

export default useInsubordinator;
