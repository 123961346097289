import { defineMessages } from 'react-intl';

export const routesData = defineMessages({
    metaTitle: {
        id: 'meta.title',
        defaultMessage: 'Title',
    },
    home: {
        id: 'route.home',
        defaultMessage: '',
    },
    insubordinator: {
        id: 'route.insubordinator',
        defaultMessage: 'insubordinateur',
    },
    weAre: {
        id: 'route.weAre',
        defaultMessage: 'nous-sommes',
    },
    weCreate: {
        id: 'route.weCreate',
        defaultMessage: 'nous-creons',
    },
    team: {
        id: 'route.team',
        defaultMessage: 'equipe',
    },
    projects: {
        id: 'route.projects',
        defaultMessage: 'projets',
    },
    contact: {
        id: 'route.contact',
        defaultMessage: 'contacts',
    },
    phone: {
        id: 'route.phone',
        defaultMessage: 'telephone',
    },
    mail: {
        id: 'route.mail',
        defaultMessage: 'courriel',
    },
    credits: {
        id: 'route.credits',
        defaultMessage: 'credits',
    },
});

export const pageTitles = defineMessages({
    home: {
        id: 'page.title.home',
        defaultMessage: 'Nous croyons',
    },
    insubordinator: {
        id: 'page.title.insubordinator',
        defaultMessage: 'Insubordinateur',
    },
    weAre: {
        id: 'page.title.weAre',
        defaultMessage: 'Nous sommes',
    },
    weCreate: {
        id: 'page.title.weCreate',
        defaultMessage: 'Nous créons',
    },
    team: {
        id: 'page.title.team',
        defaultMessage: 'Équipe',
    },
    projects: {
        id: 'page.title.projects',
        defaultMessage: 'Projets',
    },
    contact: {
        id: 'page.title.contact',
        defaultMessage: 'Contact',
    },
    phone: {
        id: 'page.title.phone',
        defaultMessage: 'Téléphone',
    },
    mail: {
        id: 'page.title.mail',
        defaultMessage: 'Courriel',
    },
    credits: {
        id: 'page.title.credits',
        defaultMessage: 'Inf',
    },
});
