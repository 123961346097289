import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const PauseIcon = ({ className, color }) => (
    <svg
        width="13"
        height="16"
        viewBox="0 0 13 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M2.67568 0L0 0L5.32432 16H8L2.67568 0Z" fill={color} />
        <path d="M7.67568 0L5 0L10.3243 16H13L7.67568 0Z" fill={color} />
    </svg>
);

PauseIcon.propTypes = propTypes;
PauseIcon.defaultProps = defaultProps;

export default PauseIcon;
