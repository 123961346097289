/* eslint-disable react/jsx-props-no-spreading, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { defineMessages, useIntl } from 'react-intl';

import BurgerButton from '../buttons/Burger';
import SidebarMenu from '../menus/SidebarMenu';
import LogoLong from '../logos/LogoLong';
import LogoInsub from '../logos/LogoInsub';
import Reload from '../icons/Reload';

import styles from '../../styles/partials/navbar.module.scss';
import Translate from './Translate';

const messages = defineMessages({
    tagline: {
        id: 'content.tagline',
        defaultMessage: 'Pour celles et ceux qui croient',
    },
});

const propTypes = {
    value: PropTypes.number.isRequired,
    menu: PropTypes.arrayOf(PropTypes.shape({ slug: PropTypes.string, url: PropTypes.string }))
        .isRequired,
    resetSite: PropTypes.func,
    className: PropTypes.string,
};

const defaultProps = {
    resetSite: null,
    className: null,
};

const Navbar = ({ className, menu, value, resetSite }) => {
    const intl = useIntl();
    const [menuOpen, setMenuOpen] = useState(false);
    const toggleMenu = useCallback(
        e => {
            e.preventDefault();
            setMenuOpen(!menuOpen);
        },
        [menuOpen, setMenuOpen],
    );

    const onResetSite = useCallback(
        e => {
            e.preventDefault();
            resetSite(true);
        },
        [menuOpen, setMenuOpen],
    );

    const closeMenu = useCallback(() => {
        setMenuOpen(false);
    }, [setMenuOpen]);

    const LogoComponent = useMemo(() => {
        if (value >= 0 && value < 50) {
            return LogoLong;
        }
        if (value >= 50) {
            return LogoInsub;
        }
        return LogoLong;
    }, [value]);

    const addedClass = useMemo(() => {
        if (value > 0 && value < 50) {
            return '';
        }
        if (value >= 50 && value < 75) {
            return 'little';
        }
        if (value >= 75 && value < 90) {
            return 'little';
        }
        if (value >= 90 && value < 100) {
            return 'animation';
        }
        return LogoLong;
    }, [value]);

    const { logoColor, logoTraitColor } = useMemo(() => {
        if (value >= 75 && value < 90) {
            return { color: '#FF0006', logoTraitColor: '#FF20FF' };
        }
        return LogoLong;
    }, [value]);

    const homeUrl = useMemo(() => (menu ? menu.find(m => m.slug === 'home').url : 'nous-croyons'));

    return (
        <nav className={classNames([styles.container, { [className]: className !== null }])}>
            {menuOpen ? <div className={styles.lightbox} onClick={toggleMenu} /> : null}
            <div className={styles.inner}>
                <div
                    className={styles.menuContainer}
                    style={{
                        opacity: menuOpen ? '1' : '0',
                        pointerEvents: menuOpen ? 'auto' : 'none',
                    }}
                >
                    <SidebarMenu menu={menu} closeMenu={closeMenu} />
                </div>
                <div className={styles.left}>
                    <BurgerButton onClick={toggleMenu} open={menuOpen} />
                    <Link to={homeUrl} className={styles.logoContainer}>
                        <div className={classNames([styles.overflowDiv, styles[addedClass]])}>
                            <LogoComponent
                                className={styles.logo}
                                color={logoColor}
                                traitColor={logoTraitColor}
                            />
                        </div>
                    </Link>
                    <button
                        type="button"
                        className={styles.reload}
                        onClick={onResetSite}
                        style={{
                            opacity: `${value !== 10 ? '1' : '0'}`,
                            pointerEvents: `${value !== 10 ? 'auto' : 'none'}`,
                        }}
                    >
                        <Reload />
                    </button>
                </div>
                <div data-text className={styles.tagLine}>
                    {intl.formatMessage(messages.tagline)}
                </div>
                <Translate className={styles.translator} />
            </div>
        </nav>
    );
};

Navbar.propTypes = propTypes;
Navbar.defaultProps = defaultProps;

export default Navbar;
