/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { defineMessages, injectIntl } from 'react-intl';

import * as AppPropTypes from '../../lib/PropTypes';
import { isMessage } from '../../lib/utils';

const messages = defineMessages({
    title: {
        id: 'meta.title',
        defaultMessage: 'Insubordination',
    },
    title_meta: {
        id: 'meta.title_meta',
        defaultMessage: '{title} | Insubordination',
    },
    description: {
        id: 'meta.description',
        defaultMessage:
            'Atelier de création transdisciplinaire qui crée des histoires évolutives multiformes et multiplateformes.',
    },
    keywords: {
        id: 'meta.keywords',
        defaultMessage:
            'Atelier de création, création, art, art visuel, design, publicité, identité, fil narratif, journalisme, documentaire, créateurs, artistes, histoires évolutives, multiplateforme, multiforme, Catherine Martellini, Simon Beaudry, numérique, interactif, expérience.',
    },
});

const propTypes = {
    intl: AppPropTypes.intl.isRequired,
    title: PropTypes.oneOfType([AppPropTypes.message, PropTypes.string]),
    description: AppPropTypes.message,
    keywords: AppPropTypes.message,
};

const defaultProps = {
    title: messages.title,
    description: messages.description,
    keywords: messages.keywords,
};

const PageMeta = ({ intl, title, description, keywords }) => {
    const customTitle =
        title !== null && !isMessage(title)
            ? intl.formatMessage(messages.title_meta, { title })
            : intl.formatMessage(messages.title);
    return (
        <Helmet>
            <title>{isMessage(title) ? intl.formatMessage(title) : customTitle}</title>
            <meta name="description" content={intl.formatMessage(description)} />
            <meta name="keywords" content={intl.formatMessage(keywords)} />
            <meta name="language" content="fr" />
        </Helmet>
    );
};

PageMeta.propTypes = propTypes;
PageMeta.defaultProps = defaultProps;

export default injectIntl(PageMeta);
