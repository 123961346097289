/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-new */
import React, { useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import Globe from '../icons/Globe';

import styles from '../../styles/partials/translate.module.scss';

const messages = defineMessages({
    translate: {
        id: 'content.translate.disclaimer',
        defaultMessage:
            'Avis de non-responsabilité : La version officielle de ce site est en français. Voyez le résultat dans d’autres langues avec un outil de traduction sans nuances.',
    },
});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const Translate = ({ className }) => {
    const intl = useIntl();
    const [popupOpened, setPopupOpened] = useState(false);
    const togglePopup = useCallback(
        (e) => {
            e.preventDefault();
            setPopupOpened(!popupOpened);
        },
        [popupOpened, setPopupOpened],
    );

    // GoogleTranslate
    useEffect(() => {
        const googleTranslateElementInit = () => {
            new window.google.translate.TranslateElement(
                {
                    pageLanguage: 'fr',
                    layout: window.google.translate.TranslateElement.FloatPosition.BOTTOM_LEFT,
                },
                'google_translate_element',
            );

        };
        const addScript = document.createElement('script');
        addScript.setAttribute(
            'src',
            '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit',
        );
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;
    }, [setPopupOpened]);

    return (
        <div
            className={classNames([
                styles.container,
                { [className]: className !== null, [styles.opened]: popupOpened },
            ])}
        >
            {popupOpened ? <div className={styles.lightbox} onClick={togglePopup} /> : null}
            <button className={styles.globeButton} type="button" onClick={togglePopup}>
                <Globe className={styles.globeIcon} />
            </button>
            <div className={styles.popup}>
                <div id="google_translate_element" className={styles.googleTranslate} />
                <div className={styles.message}>{intl.formatMessage(messages.translate)}</div>
            </div>
        </div>
    );
};

Translate.propTypes = propTypes;
Translate.defaultProps = defaultProps;

export default Translate;
