/* eslint-disable prefer-destructuring  */
import { getRelativeValue } from './utils';
import star from '../img/insubordinator/star.gif';
import psycho from '../img/insubordinator/psycho.gif';
import psycho2 from '../img/insubordinator/psych-2.gif';
import round from '../img/insubordinator/round-2.gif';
import rd from '../img/insubordinator/rd.gif';
// import rubik from '../img/insubordinator/rubik.gif';
import saturn from '../img/insubordinator/saturn.gif';
import explode from '../img/insubordinator/explode.gif';

// import dog from '../img/insubordinator/dog.jpg';

const useBackgroundInsubordinator = siteValue => {
    let imageUrl = '';
    let trait = 'default';
    let backgroundColor = '';
    let backgroundClassName = '';
    let opacity;
    let repeat;
    let size;
    let duration;
    // const colors = ['#FFFF00', '#FF00FF', '#00FF00', '#FF3333', 'rgb(0, 102, 0, 1)', 'rgb(0, 51, 102)', '#CC99CC'];
    const relativeValue = getRelativeValue(siteValue);

    if (siteValue < 10) {
        imageUrl = '';
        trait = 'default';
        backgroundColor = `rgba(255,255,255, ${1 - relativeValue})`;
    }
    if (siteValue >= 10 && siteValue < 20) {
        imageUrl = '';
    }
    if (siteValue >= 20 && siteValue < 30) {
        backgroundColor = `rgba(204, 153, 204,${0.5 * relativeValue + 0.5})`;
        imageUrl = '';
    }
    if (siteValue >= 30 && siteValue < 40) {
        trait = 'brush';
        imageUrl = '';
        backgroundColor = `rgba(0, 102, 0, ${0.5 * relativeValue + 0.5})`;
    }
    if (siteValue >= 40 && siteValue < 50) {
        backgroundColor = `rgb(250, 250, 250)`;
        opacity = 0 + relativeValue;
        trait = 'brush';
        imageUrl = `${star}`;
    }
    if (siteValue >= 50 && siteValue < 60) {
        imageUrl = `${star}`;
        backgroundColor = `rgb(250, 250, 250)`;
        trait = 'brush';
    }
    if (siteValue >= 60 && siteValue < 70) {
        imageUrl = '';
        trait = 'brush';
    }
    if (siteValue >= 70 && siteValue < 75) {
        backgroundColor = `rgb(13,13,13)`;
        imageUrl = `${saturn}`;
        trait = 'brush';
    }
    if (siteValue >= 75 && siteValue < 80) {
        backgroundColor = `rgb(0,0,0)`;
        imageUrl = `${round}`;
        trait = 'brush';
    }
    if (siteValue >= 80 && siteValue < 90) {
        opacity = 0.5 + relativeValue;
        backgroundClassName = 'colorAnimation';
        duration = 1.5 - (1 * relativeValue);
        trait = 'brush';
    }
    if (siteValue >= 90 && siteValue < 98) {
        // repeat = 'repeat';
        size = 'cover'
        trait = 'brush';

        if (siteValue < 93) {
            imageUrl = `${explode}`;
        }
        if (siteValue >= 93 && siteValue < 97) {
            size = 'contain'
            repeat = 'repeat';
            imageUrl = `${psycho2}`;
        }
        if (siteValue === 97) {
            backgroundColor = `rgb(0,0,0)`;
            imageUrl = `${rd}`;
        }
    }
    if (siteValue >= 98) {
        trait = 'brush';
        imageUrl = `${psycho}`;
        size = 'cover'
    }
    return {
        imageUrl,
        trait,
        opacity,
        backgroundColor,
        backgroundClassName,
        repeat,
        size,
        duration,
    };
};

export default useBackgroundInsubordinator;
