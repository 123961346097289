import cath00a from './catherine_00a.jpg';
import cath00b from './catherine_00b.jpg';
import cath00c from './catherine_00c.jpg';
import cath00d from './catherine_00d.jpg';
import cath01a from './catherine_01a.jpg';
import cath01b from './catherine_01b.jpg';
import cath01c from './catherine_01c.jpg';
import cath01d from './catherine_01d.jpg';
import cath01e from './catherine_01e.jpg';
import cath01f from './catherine_01f.jpg';
import cath02a from './catherine_02a.jpg';
import cath02b from './catherine_02b.jpg';
import cath02c from './catherine_02c.jpg';
import cath02d from './catherine_02d.jpg';
import cath03a from './catherine_03a.jpg';
import cath03b from './catherine_03b.jpg';
import cath03c from './catherine_03c.jpg';
import cath04a from './catherine_04a.jpg';
import cath04b from './catherine_04b.jpg';
import cath04c from './catherine_04c.jpg';
import cath05a from './catherine_05a.jpg';
import cath05b from './catherine_05b.jpg';
import cath05c from './catherine_05c.gif';
// import cath06a from './catherine_06a.jpg';
import cath07a from './catherine_07a.jpg';
import cath07b from './catherine_07b.jpg';
import cath07c from './catherine_07c.gif';
import cath07d from './catherine_07d.jpg';
import cath08a from './catherine_08a.jpg';
import cath08b from './catherine_08b.jpg';
import cath08c from './catherine_08c.jpg';
import cath08d from './catherine_08d.jpg';
import cath09a from './catherine_09a.jpg';
import cath10a from './catherine_10a.jpg';
import cath11a from './catherine_11a.gif';
import human1 from '../insubordinator/human-1.gif';

export default [
    [cath00a, cath00b, cath00c, cath00d],
    [cath01a, cath01b, cath01c, cath01d, cath01e, cath01f],
    [cath02a, cath02b, cath02c, cath02d],
    [cath03a, cath03b, cath03c],
    [cath04a, cath04b, cath04c],
    [cath05a, cath05b, cath05c],
    // [cath06a],
    [cath07a, cath07b, cath07c, cath07d],
    [cath08a, cath08b, cath08c, cath08d],
    [cath09a],
    [cath10a],
    [cath11a, human1],
];
