/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import { defineMessages, useIntl } from 'react-intl';

import styles from '../../styles/sections/mail.module.scss';

// const messages = defineMessages({});

const propTypes = {
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const ContactSection = ({ className }) => {
    return (
        <div className={classNames([styles.container, { [className]: className !== null }])}>
            <section className={styles.mail}>
                <a href="mailto:info@insubordination.co">
                    <p data-text className={styles.mailText}>INFO</p>
                    <em data-text className={styles.mailTextBlack}>@</em>
                    <p data-text className={styles.mailText}>INSUBORD</p>
                    <p data-text className={styles.mailText}>INATION</p>
                    <p>
                        <em data-text className={styles.mailTextBlack}>.CO</em>
                    </p>
                </a>
            </section>
        </div>
    );
};

ContactSection.propTypes = propTypes;
ContactSection.defaultProps = defaultProps;

export default ContactSection;
