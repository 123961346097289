import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const Pin = ({ className, color }) => (
    <svg
        width="28"
        height="40"
        viewBox="0 0 28 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 20C11 20 8 17 8 14C8 9.56152 11.4711 8 14 8C16.5289 8 20 10 20 14C20 16 17.6154 20 14 20ZM14 0C6.268 0 0 6.268 0 14C0 21.732 9 40 14 40C19 40 28 21.732 28 14C28 6.268 21.732 0 14 0Z"
            fill={color}
        />
    </svg>
);

Pin.propTypes = propTypes;
Pin.defaultProps = defaultProps;

export default Pin;
