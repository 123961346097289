import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    className: PropTypes.string,
    color: PropTypes.string,
};

const defaultProps = {
    className: null,
    color: '#B19554',
};

const Trait = ({ className, color }) => (
    <svg
        width="47"
        height="30"
        viewBox="0 0 47 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
    >
        <path d="M0.900391 19.8L0.900391 29.7L46.7004 10V0.0999947L0.900391 19.8Z" fill={color} />
    </svg>
);

Trait.propTypes = propTypes;
Trait.defaultProps = defaultProps;

export default Trait;
