/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { defineMessages, useIntl } from 'react-intl';

import simon from '../../img/simon';
import cath from '../../img/catherine';

import styles from '../../styles/sections/team.module.scss';

const messages = defineMessages({
    intro: {
        id: 'content.team.intro',
        defaultMessage: `Nous sommes un duo formé d'un artise visuel publicitaire et d'une rédactrice-journaliste.`,
    },
    simon: {
        id: 'content.team.simon',
        defaultMessage: 'Cofondateur, associé, directeur, de création',
    },
    catherine: {
        id: 'content.team.catherine',
        defaultMessage: 'Cofondatrice, associée, directrice, des contenus',
    },
    desc: {
        id: 'content.team.desc',
        defaultMessage: `Nous sommes le centre d’un collectif d’insubordonnés qui fluctuent selon la nature des mandats et leur ampleur.`,
    },
});

const propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
};

const defaultProps = {
    className: null,
};

const TeamSection = ({ className, value }) => {
    const intl = useIntl();

    const getRandomImage = useCallback( (images, rangeIndex) => {
        const rangeImages = images[rangeIndex];
        const rndIndex = Math.floor(Math.random() * rangeImages.length);
        return rangeImages[rndIndex];
    }, []);

    const [cathImage, setCathImage] = useState(getRandomImage(cath, 0));
    const [simonImage, setSimonImage] = useState(getRandomImage(simon, 0));

    useEffect(() => {
        let rangeIndex;
        if (value < 10) {
            rangeIndex = 0;
        } else if (value < 20) {
            rangeIndex = 1;
        } else if (value < 30) {
            rangeIndex = 2;
        } else if (value < 40) {
            rangeIndex = 3;
        } else if (value < 50) {
            rangeIndex = 4;
        } else if (value < 60) {
            rangeIndex = 5;
        } else if (value < 70) {
            rangeIndex = 6;
        } else if (value < 80) {
            rangeIndex = 7;
        } else if (value < 90) {
            rangeIndex = 8;
        } else if (value < 97) {
            rangeIndex = 9;
        } else {
            rangeIndex = 10;
        }

        setCathImage(getRandomImage(cath, rangeIndex));
        setSimonImage(getRandomImage(simon, rangeIndex));
    }, [value, getRandomImage]);

    return (
        <section className={classNames([styles.container, { [className]: className !== null }])}>
            <div>
                <h1 data-title className={classNames([styles.text, styles.top])}>
                    {intl.formatMessage(messages.intro)}
                </h1>
                <div className={styles.inner}>
                    <div className={styles.teamMemberContainer}>
                        <h2 data-text className={styles.title}>
                            Simon Beaudry
                            <span>
                                <a
                                    className={styles.linkedin}
                                    href="https://ca.linkedin.com/in/simon-beaudry-3046a278"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    IN
                                </a>
                                <a
                                    className={styles.link}
                                    href="mailto:simon@insubordination.co"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    CONTACT
                                </a>
                            </span>
                        </h2>
                        <div className={styles.imageContainer}>
                            <a
                                href="mailto:simon@insubordination.co"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={simonImage} alt="Simon beaudry" />
                            </a>
                            <a
                                href="https://www.richelet.ca/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div data-text className={styles.photoCredits}>
                                    © Philippe Richelet
                                </div>
                            </a>
                            <p className={styles.jobDescription}>
                                {intl
                                    .formatMessage(messages.simon)
                                    .split(',')
                                    .map(w => (
                                        <span key={w} className={styles.word}>
                                            {w}
                                        </span>
                                    ))}
                            </p>
                        </div>
                    </div>
                    <div className={styles.teamMemberContainer}>
                        <h2 data-text className={styles.title}>
                            Catherine Martellini
                            <span>
                                <a
                                    className={styles.linkedin}
                                    href="https://www.linkedin.com/in/catherine-martellini-93118765"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    IN
                                </a>
                                <a
                                    className={styles.link}
                                    href="mailto:catherine@insubordination.co"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    CONTACT
                                </a>
                            </span>
                        </h2>
                        <div className={styles.imageContainer}>
                            <a
                                href="mailto:catherine@insubordination.co"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <img src={cathImage} alt="Catherine Martellini" />
                            </a>
                            <a
                                href="https://www.richelet.ca/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <div data-text className={styles.photoCredits}>
                                    © Philippe Richelet
                                </div>
                            </a>
                            <p className={styles.jobDescription}>
                                {intl
                                    .formatMessage(messages.catherine)
                                    .split(',')
                                    .map(w => (
                                        <span key={w} className={styles.word}>
                                            {w}
                                        </span>
                                    ))}
                            </p>
                        </div>
                    </div>
                </div>
                <h1 data-title className={styles.text}>
                    {intl.formatMessage(messages.desc)}
                </h1>
            </div>
        </section>
    );
};

TeamSection.propTypes = propTypes;
TeamSection.defaultProps = defaultProps;

export default TeamSection;
