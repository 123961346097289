/* eslint-disable react/jsx-props-no-spreading, prefer-destructuring */
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { getRelativeValue } from '../../lib/utils';

const propTypes = {
    value: PropTypes.number.isRequired,
};

const defaultProps = {};

const Fonts = ({ value }) => {
    const relativeValue = useMemo(() => getRelativeValue(value), [value]);

    const loadingfonts = useMemo(
        () => (
            <style type="text/css">{`
                @import url('https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap');
                @import url('https://fonts.googleapis.com/css2?family=Arvo&family=Bodoni+Moda&family=Fredoka+One&family=Permanent+Marker&family=Poppins:wght@100&family=Sarina&family=Staatliches&display=swap');
            `}</style>
        ),
        [],
    );

    const fonts = [
        'Permanent Marker, cursive',
        '',
        'Dancing Script',
        'Impact',
        'Courier',
        'Poppins',
        'Fredoka One',
        'Staatliches',
        'Arvo',
        'Bodoni Moda',
    ];

    const fontTitle = useMemo(() => {
        let fontTitleFinal = '';
        if (value < 10) {
            fontTitleFinal = fonts[0];
        }
        if (value >= 10 && value < 20) {
            fontTitleFinal = fonts[1];
        }
        if (value >= 20 && value < 30) {
            fontTitleFinal = fonts[2];
        }
        if (value >= 30 && value < 40) {
            fontTitleFinal = fonts[3];
        }
        if (value >= 40 && value < 50) {
            fontTitleFinal = fonts[4];
        }
        if (value >= 50 && value < 60) {
            fontTitleFinal = fonts[5];
        }
        if (value >= 60 && value < 70) {
            fontTitleFinal = fonts[6];
        }
        if (value >= 70 && value < 80) {
            fontTitleFinal = fonts[7];
        }
        if (value >= 80 && value < 90) {
            fontTitleFinal = fonts[8];
        }
        if (value >= 90 && value < 97) {
            fontTitleFinal = fonts[8];
        }
        if (value >= 97) {
            fontTitleFinal = fonts[8];
        }
        return fontTitleFinal;
    }, [value]);

    const { color, bgColor } = useMemo(() => {
        let colorFinal;
        let bgColorFinal;
        if (value < 10) {
            colorFinal = `hsl(0deg 0% ${relativeValue * 110}%)`;
        }
        if (value >= 10 && value < 20) {
            colorFinal = '';
        }
        if (value >= 20 && value < 30) {
            colorFinal = '';
        }
        if (value >= 30 && value < 40) {
            bgColorFinal = 'transparent';
            colorFinal = '';
        }
        if (value >= 40 && value < 50) {
            colorFinal = `hsl(0deg 0% ${110 - relativeValue * 110}%)`;
            bgColorFinal = 'transparent';
        }
        if (value >= 50 && value < 60) {
            colorFinal = '#000';
        }
        if (value >= 60 && value < 70) {
            colorFinal = '';
        }
        if (value >= 70 && value < 75) {
            colorFinal = `#fff`;
            bgColorFinal = 'transparent';
        }
        if (value >= 80 && value < 90) {
            bgColorFinal = 'transparent';
            colorFinal = `#fff`;
        }
        if (value >= 90 && value < 97) {
            colorFinal = '';
        }
        if (value === 97) {
            colorFinal = `#000`;
        }
        if (value > 97) {
            colorFinal = `#000`;
        }
        return { color: colorFinal, bgColor: bgColorFinal };
    }, [value]);

    const { fontSize, fontWeight } = useMemo(() => {
        let sizeFinal;
        let weightFinal;
        if (value < 10) {
            sizeFinal = ``;
        }
        if (value >= 10 && value < 20) {
            sizeFinal = '';
        }
        if (value >= 20 && value < 30) {
            sizeFinal = '';
        }
        if (value >= 30 && value < 40) {
            sizeFinal = '';
        }
        if (value >= 40 && value < 50) {
            sizeFinal = '';
        }
        if (value >= 50 && value < 60) {
            sizeFinal = '';
        }
        if (value >= 60 && value < 70) {
            sizeFinal = '';
        }
        if (value >= 70 && value < 80) {
            sizeFinal = '';
        }
        if (value >= 80 && value < 90) {
            sizeFinal = '';
        }
        if (value >= 90 && value < 97) {
            sizeFinal = '';
            weightFinal = '700';
        }
        if (value >= 97) {
            sizeFinal = '';
            weightFinal = '700';
        }
        return { fontSize: sizeFinal, fontWeight: weightFinal };
    }, [value]);

    return (
        <style type="text/css">
            {loadingfonts}
            {`
                [data-title] {
                    font-family: ${fontTitle} !important;
                    color: ${color} !important;
                    font-size: ${fontSize} !important;
                    font-weight: ${fontWeight} !important;
                    background-color: ${bgColor} !important;
                    text-transform: 'none' !important;
                    line-height: 1em;
                }

                [data-text] {
                    font-family: ${fontTitle} !important;
                    font-weight: ${fontWeight} !important;
                    color: ${color} !important;
                    background-color: ${bgColor} !important;
                    text-transform: 'none' !important;
                }
            `}
        </style>
    );
};

Fonts.propTypes = propTypes;
Fonts.defaultProps = defaultProps;

export default Fonts;
